@import 'styles/_variables.scss';

.header {
  display: grid;
  gap: 1rem;
  align-items: center;
  margin-bottom: 16px;

  @media (min-width: $breakLG) {
    grid-template-columns: 1fr 3fr;
  }

  h2 {
    color: $gray;
  }

  .tabs {
    div {
      justify-content: flex-end !important;
    }
  }
}

.tab {
  font-weight: bold !important;
  text-transform: none !important;

  &[aria-selected='true'] {
    color: $gray !important;
  }
}

.createRequest {
  margin-inline: auto;
  margin-block-start: 2rem;

  @media (min-width: $breakMD) {
    width: 50%;
  }
}

.nameCol {
  color: $darkGreen;
}

.requestLoading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.requestHandlerPending {
  display: flex;
  margin-inline: auto !important;
  gap: 1rem;
  font-size: 1.2rem;

  i {
    cursor: pointer;
  }

  .approve {
    color: $darkGreen;

    &:hover {
      color: $darkGreen2;
    }
  }

  .reject {
    color: $error600;

    &:hover {
      color: $error700;
    }
  }
}
