@import 'styles/_variables.scss';

.title {
  color: $gray600;
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 8px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  .button {
    width: 80% !important;
    padding-block: 1.1rem;
    background-color: $darkGreen;
    color: $white;
    font-weight: bold;
    margin-inline: auto;
    margin-top: 20px;

    &:hover {
      background-color: $darkGreen2 !important;
    }

    &:hover {
      background-color: $darkGreen2 !important;
    }

    &:disabled {
      background-color: $lightGray !important;
      color: $lightGrayText !important;
    }

    .loadingIcon {
      svg {
        color: $white !important;
      }
    }
  }
}

@media (min-width: $breakLG) {
  .container {
    padding-inline: 64px;
  }
}
