@import 'styles/_variables.scss';

.wrapper {
  margin-block-start: 16px;
  max-height: 600px;
  overflow-y: auto;
}

.title {
  margin-bottom: 12px;
}

.accessWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.accessType {
  margin-left: 16px;
}

.permissionText {
  color: $gray500;
  font-size: calc(12 / 16 * 1rem);
  margin-top: 8px;
}
