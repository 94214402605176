@import '../../../../styles/_variables.scss';

.button {
  width: fit-content;
  margin-left: auto;
  background-color: $darkGray;
}

.hideFileInput {
  position: fixed;
  right: -100%;
}

.menuIcon {
  min-width: 36px;
}

.divider {
  margin-block: 4px;
}

.wrapper {
  min-width: 300px;
}

.input {
  margin-bottom: 24px !important;
}

.cancelButton {
  padding: 16px !important;
}
