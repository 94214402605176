@import 'styles/_variables.scss';

.loading {
  width: 100%;
  display: flex;
  justify-content: center;

  .button {
    width: 95% !important;
    margin-inline: auto !important;
    padding: 1rem 2rem !important;
  }
}

.employeeName {
  color: $gray;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.contentContainer {
  max-height: 70vh;
  overflow-y: scroll;

  display: grid;
  gap: 1.5rem;

  .inputControl {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    label {
      flex-basis: 40%;
      flex-shrink: 0;
      color: $black2;
    }
  }
}
