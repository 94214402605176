@import 'styles/_variables.scss';

.wrapper {
  margin-bottom: 16px;
  margin-left: auto;
}

.label {
  display: flex;
  gap: 0.5rem;
}
