.stepper {
  justify-content: center !important;
  margin-top: 16px !important;
  background-color: transparent !important;

  .dot {
    width: 12px !important;
    height: 12px !important;
    margin-inline: 4px !important;
  }
}
