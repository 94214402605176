@import 'styles/_variables.scss';

.label {
  color: $white !important;
}

.inputRoot {
  color: $white !important;

  & > fieldset {
    border-color: $white !important;
  }

  & > svg {
    color: $white !important;
  }
}
