@import 'styles/_variables.scss';

.content {
  margin-block: 16px;

  .descriptionHeader {
    font-size: 1rem;
    color: $gray600;
    margin-bottom: 16px;
  }

  .description {
    border: 1px solid $gray100;
    padding: 16px;
    border-radius: 3px;
    max-height: 300px;
    overflow-y: auto;
  }

  .managersContainer {
    margin-top: 24px;
    color: $gray500;

    .managersTitle {
      display: block;
      margin-bottom: 8px;
    }
  }
}
