@import 'styles/_variables.scss';

.event {
  display: block;
  margin-bottom: 1.3rem;

  strong {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: $black2;
    font-size: 1rem;
    letter-spacing: -0.025rem;
    margin-bottom: 0.25rem;

    .eventButtons {
      opacity: 0;
      transition: all 100ms ease-in;
      display: flex;
      gap: 0.5rem;

      button {
        cursor: pointer;
        border: none;
        background-color: transparent;
        font-size: 0.8rem;
        color: $black;

        i {
          transition: all 200ms ease-in-out;

          &:hover {
            color: $darkGreen2;
          }
        }
      }
    }

    &:hover > .eventButtons {
      opacity: 1;
    }
  }

  span {
    font-size: 1.1rem;
  }

  @media (min-width: $breakLG) {
    strong {
      font-size: 1.25rem;
    }

    span {
      font-size: 1rem;
    }
  }
}

.eventDone {
  color: $lightGrayText;
  vertical-align: baseline;

  .checkmark {
    color: $darkGreen2;
    margin-left: 0.5rem;
  }

  strong {
    font-weight: 500;
    color: $lightGrayText;
  }
}

.eventType {
  display: flex;
  align-items: center;

  &::before {
    display: block;
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
    background-color: var(--color);
  }
}
