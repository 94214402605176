@import 'styles/_mixins.scss';

.pageRoot {
  height: 100%;
}

.pageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.pageContainer {
  @extend %ajustedContent;
  width: 100%;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 2rem;
  padding-inline: 1rem;
}
