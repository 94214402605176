@import 'styles/_variables.scss';

.wrapper {
  margin-block: 16px;
  margin-bottom: 24px;
  display: flex;

  @media (max-width: $breakMD) {
    flex-direction: column;
    gap: 16px;
  }
}

.informationSection {
  margin-right: auto;
}

.recurrencyHelper {
  color: $gray500;
  margin-top: 8px;
}

.buttons {
  display: flex;
  gap: 16px;
}
