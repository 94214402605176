@import 'styles/_variables.scss';

.header {
  margin-bottom: 24px;
  border-bottom: 1px solid $gray200;
  padding-bottom: 16px;
}

.mainSection {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  gap: 24px;
}

.content {
  flex: 1;
  flex-shrink: 999999999;
  position: sticky;
  top: 16px;
  height: fit-content;
  max-height: 50vh;
  padding: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: $shadow2;
  overflow-y: auto;

  @media (max-width: $breakMD) {
    display: none;
  }
}

.contentLink {
  display: block;
  margin-bottom: 8px;
  color: $gray500;

  &:first-of-type {
    margin-top: 8px;
  }

  &:hover {
    color: $primary500;
    text-decoration: underline;
  }

  &[data-active='true'] {
    color: $primary500;
    font-weight: 600;
  }
}

.tutorials {
  flex: 3;
  flex-shrink: 0;
}

.sectionTitle {
  text-transform: capitalize;
  margin-top: 8px;
  margin-bottom: 4px;
}

.videoWrapper {
  flex: 1;
  margin-top: 24px;
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid $gray200;

  &:last-of-type {
    border-bottom: none;
  }

  &:first-of-type {
    margin-top: 0;
  }
}

.videoContainer {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  width: 100%;
  height: 0;
}

.videoContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videoTitle {
  margin-top: 16px;
  margin-bottom: 8px;
}

.videoDescription {
  margin-bottom: 16px;
  color: $gray600;
  line-height: 1.65;
  font-size: calc(18 / 16 * 1rem);
}
