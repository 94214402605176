// **************
// Breakpoints
// **************
$breakSM: '420px';
$breakMD: '768px';
$breakLG: '1024px';
$maxBreakLG: '1280px';
$breakXL: '1366px';

/*
LEGACY COLORS
*/
$accentGreen: #a5e67f;
$accentGreen2: #a0c28b;
$lightGreen: #d8ffc2;
$lightGreen2: #e5f8da;
$darkGreen: #507938;
$darkGreen2: #3a681f;
$darkGreen3: #517b38;
$darkGreen4: #84b865;
$darkGreenText: #2e4620;
$white: #fff;
$black: #050505;
$black2: #25252a;
$black3: #1a1a1a;
$completelyBlack: #000000;
$lightGrayText: #b3b3b3;
$lightGray: #f0f2f5;
$lightGray50: #e0e0e0;
$lightGray2: #eaedf1;
$lightGray3: #fcfcfc;
$lightGray4: #c4c4c4;
$lightGray5: #e4e4e4;
$lightGray6: #bcbcbc;
$lightGray7: #d0d0d0;

$primary50: #c3ddb5;
$primary100: #b8d6a7;
$primary200: #a2c98b;
$primary300: #8cbc6f;
$primary400: #75af53;
$primary500: #629545;
$primary600: #507938;
$primary700: #375326;
$primary800: #1d2c15;
$primary900: #040603;
$gray50: #f3f3f3;
$gray100: #e4e4e4;
$gray200: #c8c8c8;
$gray300: #acacac;
$gray400: #909090;
$gray500: #747474;
$gray600: #585858;
$gray700: #3c3c3c;
$gray800: #202020;
$gray900: #040404;

$gray: #585858;
$gray2: rgba(0, 0, 0, 0.54);
$gray3: hsla(0, 0%, 0%, 0.12);
$gray4: #9a9a9a;
$gray5: #b7b7b7;
$darkGray: #484848;

$shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.2);
$shadow2: 0px 3px 22px rgba(0, 0, 0, 0.09);
$shadow3: 0px 2px 6px rgba(0, 0, 0, 0.15);
$shadow4: 0px 1px 18px rgba(0, 0, 0, 0.25);
$shadow5: -4px 4px 4px rgba(0, 0, 0, 0.25);
$boxShadow: 0px 3px 9px rgba(0, 0, 0, 0.22);

$shadowRight: 1px 0 5px hsla(0, 0%, 0%, 0.15), 2px 0 10px hsla(0, 0%, 0%, 0.05), 3px 0 15px hsla(0, 0%, 0%, 0.02);
$constrastTopShadow: 0px -4px 17px 0px rgba(0, 0, 0, 0.17);

$error100: #f5bfbd;
$error400: #f06e6e;
$error600: #c9342f;
$error700: #c9201a;
$warning: #ffd000;
$inProgress100: #fef3c7;
$inProgress600: #f59e0b;
$workingOn100: #dbeafe;
$workingOn600: #3b82f6;
$completed100: #d1fae5;
$completed600: #059669;

$events: #99cf90;
$posts: #8cc0d6;
$emails: #9a9b9d;

// **************
// Brand Colors
// **************
$facebook: #4267b2;
$instagram: #c13584;

// **************
// Extras
// **************
$gray100border: 1px solid $gray100;

$gray100: #f2f2f2;
$gray200: #e4e4e4;
$gray300: #c4c4c4;
$gray400: #9a9a9a;
$gray500: #585858;
$gray600: #343434;

$primary100: #d8ffc2;
$primary200: #99cf90;
$primary300: #a0c28b;
$primary400: #8cbc6f;
$primary500: #517b38;

$white: #fff;
$black: #050505;
$black2: #25252a;

@mixin box {
  background-color: $white;
  box-shadow: $shadow;
  border-radius: 9px;
}
