@import 'styles/_mixins.scss';

.helper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: $gray500;
  font-size: calc(18 / 16 * 1rem);
  min-height: 200px;

  &::after {
    content: '...';
  }
}

/* THE FORM */

.formWrapper {
  display: flex;
  gap: 24px;

  @media (max-width: $breakMD) {
    flex-direction: column;
  }

  & > section {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

/* THE DEDUCTIONS */

.deductionsHeader {
  display: flex;
  align-items: center;

  & > h3 {
    color: $gray500;
    font-size: calc(18 / 16 * 1rem);
    flex: 1;
  }

  & > div {
    display: flex;
    gap: 16px;

    @media (max-width: $breakMD) {
      flex-direction: column;
      gap: 8px;
    }
  }

  @media (max-width: $breakMD) {
    align-items: stretch;
    flex-direction: column;
    gap: 16px;
  }
}

.deductionsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 24px;
  max-height: 310px;
  overflow-y: auto;
}

.deductionItem {
  padding: 16px;
  border: 2px solid $gray700;
  border-radius: 18px;
  box-shadow: $shadow2;
  width: 30%;

  &.deductionActive {
    border-color: $primary500;
  }

  @media (max-width: $breakMD) {
    width: 48%;
  }

  @media (max-width: $breakSM) {
    width: 100%;
  }
}

.deductionItemTitle {
  display: flex;
  justify-content: space-between;
  font-size: calc(20 / 16 * 1rem);
  color: $black;
  margin-bottom: 4px;

  & > button {
    padding: 0 !important;
    font-size: calc(18 / 16 * 1rem) !important;

    &:hover {
      background-color: transparent !important;
    }
  }
}

.deductionItemType {
  display: block;
  color: $gray500;
  margin-bottom: 16px;
}

.deductionItemValue {
  color: $primary500;
  font-size: calc(30 / 16 * 1rem);
  font-weight: bold;
}

.menuIcon {
  min-width: 0 !important;
  margin-right: 8px !important;
  color: inherit !important;
}

.deleteItem {
  color: $error600 !important;
}

/* THE FILES */

.filesHeader {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  & > h3 {
    color: $gray500;
    font-size: calc(18 / 16 * 1rem);
    flex: 1;
  }

  & > div {
    display: flex;
    gap: 16px;
  }
}

.uploadFileInput {
  display: flex;
}

.filesSection {
  max-height: 200px;
  overflow-y: auto;
  @include scrollbar;
}

.fileArticle {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border-bottom: 1px solid $gray300;
  padding-block: 16px;

  &:last-of-type {
    border-bottom: none;
  }

  i {
    font-size: 1.1rem;
  }

  .name {
    flex: 1;
  }

  .actions {
    flex-shrink: 0;
    display: flex;
    gap: 1rem;
    border-left: 1px solid $gray300;
    padding-left: 24px;
    overflow: hidden;

    i {
      color: $gray500;
      cursor: pointer;
      transition: color 100ms linear;

      &:hover {
        color: var(--color);
      }
    }
  }
}
