@import 'styles/_variables.scss';

.wrapper {
  line-height: 1.5;
  min-height: 100vh;

  p {
    margin-top: 0;
    margin-bottom: 20px;
  }

  & h2,
  & h3 {
    margin: 1rem 0;
  }

  & ul {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  & a {
    color: $primary500;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      text-decoration: revert;
    }
  }
}

.privacyPolicyPage {
  & .logo {
    width: 9rem;
    margin-top: 2rem;
  }

  & h1 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: center;
  }
}
