@import 'styles/_mixins.scss';

.form {
  display: grid;
  gap: 1.25rem;
}

.managerSelector {
  display: grid;
  gap: 1rem;
  margin-bottom: 1rem;

  .chipSection {
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.25rem;
    column-gap: 1rem;
    max-height: 120px;
    overflow-y: auto;
    @include scrollbar;
  }

  .emptyManagers {
    color: $gray400;
    font-size: 0.9rem;
  }
}

.button {
  padding-block: 1rem !important;
}
