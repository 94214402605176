@import 'styles/_variables.scss';

.wrapper {
  width: auto;
  display: grid;
  margin-bottom: 24px;
  gap: 16px;
}

.label {
  padding-left: 24px !important;
}

.label.noPaddingLeft {
  padding-left: 0 !important;
}

@media (min-width: $breakMD) {
  .wrapper {
    grid-template-columns: 2fr 1fr;
  }
}
