@import 'styles/_mixins.scss';

.content {
  font-size: 1rem;

  h1 {
    font-size: 2.1rem;

    &:first-of-type {
      margin-top: 0;
    }
  }

  h2 {
    font-size: 1.6rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  h4 {
    font-size: 0.9rem;
  }

  @media (min-width: $breakMD) {
    h1 {
      font-size: 3rem;
    }

    h2 {
      font-size: 2.4rem;
    }

    h3 {
      font-size: 1.7rem;
    }

    h4 {
      font-size: 1.3rem;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    font-weight: bold;
    margin-bottom: 0.5em;
  }

  p {
    line-height: 1.75;

    &[data-empty='true'] {
      color: $gray400;
    }
  }

  a {
    color: $primary600;
  }

  a {
    cursor: pointer;
    text-decoration: underline;
  }

  p a:hover {
    color: $primary400;
  }

  blockquote {
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 12px;
    padding-left: 16px;
    color: #495057;
    border-left: 4px solid $primary400;
  }

  ul,
  ol {
    margin-top: 12px;
    padding-left: 32px;
    list-style-position: outside;
  }

  *:global(.ql-align-center) {
    text-align: center;
  }

  *:global(.ql-align-right) {
    text-align: right;
  }

  pre {
    font-size: 0.9rem;
    color: $white;
    background-color: $gray500;
    padding: 4px 8px;
    margin-block: 8px;
    border-radius: 6px;
    white-space: pre-wrap;
  }

  figure {
    height: 300px;
    width: 100%;
  }

  img {
    margin-block: 8px;
    margin-inline: auto;
    max-height: 300px;
    border-radius: 3px;
    box-shadow: $shadow2;
  }

  table {
    border-collapse: collapse;
    width: fit-content;
    margin-block: 8px;
    margin-inline: auto;

    td,
    th {
      min-width: 2em;
      padding: 0.4rem;
      border: 1px solid $gray300;
    }

    th {
      background-color: $gray100;
      font-weight: bold;
      color: $primary500;
    }
  }
}
