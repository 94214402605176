@import 'styles/_mixins.scss';

.headerMenu {
  top: 0;
  width: 100%;
  display: flex;
  height: 3.2rem;
  position: sticky;
  background: $white;
  border-bottom: 0.1rem solid $lightGray2;
  z-index: 100;

  .content {
    @extend %ajustedContent;

    .menuItem {
      height: 100%;
      display: flex;
      color: $black2;
      cursor: pointer;
      padding: 0 1rem;
      align-items: center;
      transition: all 0.2s;
      text-decoration: none;
      border-left: 0.1rem solid $lightGray2;

      &.active {
        font-weight: bold;
        color: $darkGreen;
      }

      i {
        margin-right: 0.4rem;

        &:nth-child(2) {
          margin: 0 0 0 0.4rem;
        }
      }

      &:last-child {
        border-right: 0.1rem solid $lightGray2;
      }

      &:hover {
        color: $darkGreen;
        transition: all 0.2s;
      }
    }
  }
}

.menuWithOptions {
  .linkItem {
    text-decoration: none;
    color: $black2;

    i {
      margin-right: 0.4rem;
    }

    &:hover {
      color: $darkGreen;
      transition: all 0.2s;
    }

    &.active {
      font-weight: bold;
      color: $darkGreen;
    }
  }

  &.active {
    font-weight: bold;
    color: $darkGreen;
  }

  div {
    min-width: 7rem;
    margin: 2.4rem 0 0;

    &:first-child {
      margin: 0;
    }
  }
}
