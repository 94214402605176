@import 'styles/_variables.scss';

.wrapper {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 2px solid transparent;
  border-radius: 4px;
  padding: 8px;
  transition: all 100ms linear;
  font-size: 0.75rem;

  &:hover {
    border-color: $primary100;
  }
}

.wrapper.selected {
  border-color: $primary500;

  &:hover {
    border-color: $primary100;
  }
}

.icon {
  font-size: 2rem;
  margin-bottom: 16px;
}
