@import 'styles/_variables.scss';

.wrapper {
  justify-self: flex-end;
}

.base {
  margin-bottom: 8px;
  color: $gray500;
  display: flex;
  align-items: center;

  &::before {
    display: block;
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
  }
}

.base.events::before {
  background-color: $events;
}

.base.post::before {
  background-color: $posts;
}

.base.email::before {
  background-color: $emails;
}

.legends {
  display: block;
  font-size: 1.1rem;
  color: $gray800;
  margin-bottom: 16px;
}
