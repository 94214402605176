@import 'styles/variables.scss';

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-block-start: 48px;
  margin-block-end: 56px;
}

.header {
  font-size: bold;
  font-size: 2rem;
  color: $gray600;
  margin-bottom: 56px;
}

// Estilos para las tarjetas

.cards {
  display: flex;
  justify-content: center;
  width: 100%;
}

.cardWrapper {
  background-color: $white;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 6px;
  box-shadow: 0px 20px 30px rgba(59, 59, 59, 0.3);
  color: $gray600;
  transition: transform 200ms ease-in-out;
  flex: 1;

  &:hover {
    transform: scale(1.05);
  }
}

.cardHeader {
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.2px;
}

.cardSubheader {
  font-weight: bold;
  font-size: 1.1rem;
}

.cardIcon {
  display: block;
  margin-block: 48px;
}

.cardPrice {
  font-size: 2rem;
  font-weight: bold;
  color: $primary500;
  margin-bottom: 36px;
}

.cardFunctions {
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 36px;

  &:hover {
    text-decoration: none;
  }
}

.proPlanCard {
  background-color: $primary400;
  color: $white;
  margin-left: -12px;
  margin-top: -8px;
  margin-bottom: -8px;
  transform: scale(1.05);

  &:hover {
    transform: scale(1.1);
  }
}

.proPlanCard > .cardPrice {
  color: $white;
}

.proPlanCard > .cardFunctions {
  color: $gray600;
}

@media (min-width: $breakMD) {
  .cardWrapper {
    max-width: 365px;
    // width: 30%;
    // flex: 0;
  }

  .proPlanCard {
    margin-left: -24px;
    margin-top: -16px;
    margin-bottom: -16px;
  }
}
