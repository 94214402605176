@import 'styles/_variables';

.mobileMenu {
  justify-self: flex-start;
}

.button {
  padding: 0.5rem;
  width: min-content;
}

.icon {
  color: $white !important;
  font-size: 1.25rem;
}

.paper {
  background-color: $gray700 !important;
}