@import 'styles/_variables.scss';

.title {
  font-size: 1.25rem;
  font-weight: bold;
  color: $white;
  margin-bottom: 4px;
}

.titleHelper {
  font-size: 0.875rem;
  color: $gray300;
  margin-bottom: 16px;
}
