@import 'styles/_variables.scss';

.viewsContainer {
  margin-block: 32px;
  padding: 24px;
  background-color: $white;
  box-shadow: $shadow;
  border-radius: 10px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid $gray100;
  margin-inline: -24px;
  padding-inline: 24px;
  padding-bottom: 16px;

  @media (min-width: $breakMD) {
    flex-direction: row;
  }

  .title {
    width: 100%;
    text-align: left;
    color: $gray600;
    font-size: 1.25rem;

    @media (min-width: $breakMD) {
      width: fit-content;
    }
  }

  div:global(.MuiTabs-root) {
    width: 100%;
  }

  .tab {
    font-size: 1rem !important;
    text-transform: capitalize !important;
    color: $gray500 !important;
  }

  .tabSelected {
    font-weight: bold !important;
  }
}

.viewSection {
  margin-block: 24px;
}
