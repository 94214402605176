@import 'styles/_variables';

.navigationLink {
  font-size: 1.15rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 0.75rem;
  font-weight: 500;
  color: $white;
  transition: all .25s;
  border-right: 10px solid transparent;

  &:hover {
    color: $darkGreen3;
  }
}

.activeLink {
  background-color: $primary500 !important;
  border-color: $primary400;
  font-weight: 500;

  &:hover {
    color: $white;
  }
}