@import 'styles/_variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 2px solid $gray300;
  border-radius: 6px;
  height: 100%;
  background-color: $white;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.22);
}

.title {
  display: block;
  margin-bottom: 36px;
  color: $gray500;
  font-size: 1.25rem;
}

.chartWrapper {
  flex: 1;

  & > div {
    min-height: 250px;
  }
}
