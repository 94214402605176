@import 'styles/_mixins.scss';

.wrapper {
  margin-block: 16px;
}

.infoSection {
  display: grid;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid $gray100;
  margin-inline: -24px;
  padding-inline: 24px;
}

.contentMarkup {
  padding: 16px;
  border: 1px solid $gray100;
  border-radius: 4px;
  min-height: 200px;
  max-height: 250px;
  overflow-y: auto;
  @include scrollbar;
}

@media (min-width: $breakMD) {
  .infoSection {
    grid-template-columns: 32% 68%;

    & > div:first-of-type {
      text-align: justify;
    }
  }

  .subGrid {
    width: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
