@import 'styles/_variables.scss';

.inputRoot {
  color: $white !important;

  & > fieldset {
    border: 1px solid $white !important;
  }
}

.focused {
  & > fieldset {
    border: 2px solid $white !important;
  }
}

.label {
  color: $white !important;
}
