@import 'styles/_variables.scss';

.loadingState {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paper {
  height: 320px !important;
  width: 350px !important;
  padding: 8px;
  display: flex;
  flex-direction: column;
}

.foldersSection {
  height: 200px !important;
  overflow-y: auto;
  margin-inline: -8px;
  padding-inline: 8px;
}

.header {
  height: 50px;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: $gray200;
  border-bottom: 1px solid $gray300;
  margin: -8px -8px 8px -8px;
  padding: 8px 8px 8px 8px;

  i {
    font-size: 1rem;
    color: $gray600;
  }

  p {
    font-size: 1.25rem;
    font-weight: 500;
    color: $gray600;
  }
}

.footer {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 16px;
}

.folder {
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 8px;
  cursor: pointer;
  margin-inline: -8px;

  &:hover {
    background-color: $gray100;
  }

  i {
    font-size: 1.25rem;
    color: $gray600;
  }

  p {
    font-size: 1rem;
    font-weight: 500;
    color: $gray600;
    margin-right: auto;
  }

  &.folderActive {
    background-color: $gray200;
  }
}
