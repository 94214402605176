@import 'styles/_variables.scss';

.cookiesDialogContainer {
  display: flex;
  padding-inline: 2rem;
  padding-bottom: 1.25rem;
  flex-direction: column;
  gap: 2rem;

  .header {
    color: $black;
    text-align: center;
    font-size: 1rem;
  }

  .content {
    text-align: justify;
    color: $gray500;
    font-size: 1rem;
  }

  .getMoreDetails {
    width: 80%;
    margin: auto;
    text-align: center;
    font-weight: lighter;
    color: $gray500;

    .link {
      color: $darkGreen;
      text-decoration: underline;
    }
  }

  .acceptButton {
    padding-block: 0.75rem !important;
  }
}