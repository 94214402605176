@import 'styles/_mixins.scss';

.form {
  display: flex !important;
  flex-direction: column !important;
  gap: 1.25rem;
}

.editorRoot {
  margin-inline: 0;

  & :global(.quill) {
    & :global(.ql-container) {
      & :global(.ql-editor) {
        max-width: none;
        min-height: 200px;
        max-height: 300px;
        overflow: auto;
        @include scrollbar;
      }
    }
  }
}

.selectRoot {
  margin-inline: -64px;
}

.chipSection {
  width: 100%;
  margin-inline: auto;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.25rem;
  column-gap: 1rem;
  max-height: 120px;
  overflow-y: auto;
  @include scrollbar;
}

.emptyManagers {
  color: $gray400;
  font-size: 0.9rem;
}

@media (min-width: $breakLG) {
  .editorRoot {
    margin-inline: -64px;
  }
}
