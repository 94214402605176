@import "styles/_variables.scss";

.maintenancePage {
  display: flex;
  margin-top: 4rem;
  align-items: center;
  flex-direction: column;

  h1 {
    font-size: 1.6rem;
    color: $darkGreen;
    letter-spacing: 0.1rem;

    @media (min-width: $breakLG) {
      font-size: 2.2rem;
    }
  }

  p {
    width: 20rem;
    color: $black2;
    font-size: 1.1rem;
    margin-top: 0.5rem;

    @media (min-width: $breakLG) {
      width: 55rem;
      font-size: 1.4rem;
    }
  }

  .logoImage {
    width: 20rem;
    margin-top: -5rem;
  }

  .workingImage {
    width: 18rem;
    filter: brightness(1.4);
    mix-blend-mode: multiply;
  }
}
