@import 'styles/_variables';

.filtersContainer {
  display: grid;
  width: 55%;
  grid-template-columns: 1fr 1fr 3fr;
  gap: 1.25rem;
} 

@media (max-width: $breakMD) {
  .filtersContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }
}