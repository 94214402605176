@import 'styles/_variables.scss';

.labelContainer {
  padding: 0 0.2rem;
}

.facebookIcon {
  color: $facebook;
}

.instagramIcon {
  color: $instagram;
}
