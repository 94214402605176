@import 'styles/_variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.root {
  flex: 1;
}

.image {
  background-image: url('login-background.svg');
  background-repeat: no-repeat;
  background-color: $lightGray;
  background-size: cover;
  background-position: center;
}

.paper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
}

.form {
  width: '100%';
}

.logo {
  width: 16rem;
  margin-bottom: 1rem;
}

.button {
  background-color: $darkGreen !important;
  margin-bottom: 1rem !important;
}

.languageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  color: $lightGrayText;
}

.loadingIcon {
  margin-right: 0.5rem;

  svg {
    color: $white !important;
  }
}

.resetPasswordButton {
  width: 11rem;
  display: flex;
  cursor: pointer;
  padding: 0.2rem;
  color: $darkGreen2;
  border-radius: 0.2rem;
  text-decoration: none;
  justify-content: center;
  margin: 0 auto 1rem auto;
  border: 0.1rem solid $darkGreen2;

  &:hover {
    background: $lightGreen2;
    box-shadow: $shadow2;
  }
}
