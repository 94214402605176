@import 'styles/_mixins.scss';

.wrapper {
  background-color: $white;

  margin-inline: -24px;
  padding-inline: 24px;
  padding-block-start: 16px;
  border-top: 1px solid $gray100;
  box-shadow: $constrastTopShadow;
}

.toggler {
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  color: $gray400;

  &:hover {
    color: $gray500;
  }
}

.buttons {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.commentsWrapper {
  padding-block: 16px;
  padding-inline: 24px;
  margin-top: 36px;
  margin-inline: -24px;
  border-top: 1px solid $gray100;
  max-height: 400px;
  overflow-y: auto;
  @include scrollbar;
}

.commentsTitle {
  display: block;
  color: $gray600;
  margin-bottom: 24px;
}

.commentsEmpty {
  color: $gray300;
  margin-bottom: 16px;
}
