@import 'styles/_variables.scss';

.dialogPaper {
  height: 400px !important;
}

.searchField {
  border-radius: 4px !important;
  transition: background-color 0.12s ease-in-out !important;

  & * {
    cursor: pointer !important;
  }

  & input {
    margin-left: 8px !important;
  }

  &:hover {
    background-color: $gray100 !important;
  }
}

// For the modal
.noDisplay {
  display: none;
}

.searchForm {
  margin-inline: -24px;
  margin-block: -16px;
}

.searchInput {
  border: none;
  width: 100%;
  padding: 16px 24px;
  font-weight: 400;

  &:focus {
    outline: none;
  }
}

.poweredByWrapper {
  padding: 16px 24px;
}

.poweredBy {
  width: 140px;
  margin-left: auto;
}

.hitsList {
  list-style-type: none;
  padding: 0;
}

.hit {
  margin-inline: -24px;
  padding-inline: 24px;
  border-bottom: 1px solid $gray200;
  padding-block: 16px;

  &:first-of-type {
    margin-top: -16px;
  }

  &:last-of-type {
    border-bottom: none;
    margin-bottom: -16px;
  }
}

.hitTitle {
  display: block;
  font-size: 1.1rem;
  margin-bottom: 8px;
}

.hitContent {
  color: $gray600;
  font-size: 0.9rem;
  line-height: 1.5;
}

.emptyQueryMessage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.125rem;
  color: $gray500;

  &::after {
    content: '...';
  }
}

.shortcut {
  display: inline-block;
  min-width: fit-content;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: $gray200;
  color: $gray600;
  font-size: 0.8rem;
  font-weight: 500;
  margin-left: 8px;
  flex: 1;
}
