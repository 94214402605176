@import 'styles/_variables.scss';

.header {
  color: $gray500;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 42px;
}

.paragraph {
  display: block;
  max-width: 60%;
  color: $gray500;
  text-align: center;
  margin-bottom: 16px;
  margin-inline: auto;

  & > span {
    color: $primary400;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.paragraph.first {
  font-size: 1.25rem;
}

.paragraph.second {
  font-size: 1.1rem;
}

.buttonRoot {
  width: 60%;
  display: block !important;
  margin-inline: auto !important;
  margin-block: 48px !important;
  padding: 18px 20px !important;
}

.buttonLabel {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-transform: none !important;

  & .mainLabel {
    margin-right: 16px;
  }
}

// For the success
.successWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  color: $gray500;
  margin-inline: auto;
  text-align: center;
}

.checkWrapper {
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: $white;
  background-color: $primary400;
  border-radius: 50%;
}

.successFirst {
  font-size: 1.25rem;
}
.successSecond {
  font-size: 1.1rem;
}
