@import 'styles/_variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.errorCode {
  color: $primary500;
  font-size: calc(64 / 16 * 1rem);
  font-weight: 700;
  margin-bottom: 16px;
}

.title {
  font-size: calc(24 / 16 * 1rem);
  font-weight: 700;
  color: $gray700;
  margin-bottom: 4px;
}

.content {
  font-size: calc(16 / 16 * 1rem);
  color: $gray600;
  margin-bottom: 16px;
}
