@import 'styles/_variables.scss';

.modalContent {
  display: grid;
  place-items: center;
  gap: 1.5rem;

  .dayDiff {
    width: 100%;
    text-align: left;
    font-weight: bold;
    color: $gray;
  }

  .button {
    width: 95% !important;
    margin-top: 12px !important;
    padding: 1rem 2rem !important;
    background-color: $darkGreen !important;
    color: $white !important;
    font-weight: bold !important;

    &:disabled {
      cursor: not-allowed !important;
      color: $gray !important;
      background-color: $lightGray2 !important;
    }
  }
}
