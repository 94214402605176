@import 'styles/_variables.scss';

.footer {
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
  min-height: 40px;
}

.actions {
  width: 100% !important;
  display: flex !important;
  justify-content: flex-end !important;
  gap: 8px !important;
}

.extraMessage {
  color: $gray500;
  margin-bottom: 8px;
}
