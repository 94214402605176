@import 'styles/_variables.scss';

.wrapper {
  margin-top: -16px;
  padding-inline: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.warningIcon {
  width: 48px;
  height: 48px;
  background-color: $error400;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-inline: auto;
}

.warningHelper {
  font-weight: bold;
  color: $gray500;
  margin-block: 16px;
}

.buttons {
  width: 100%;
  display: flex;
  gap: 24px;
  padding-inline: 24px;
}

.warningFooter {
  font-size: 0.85rem;
  color: $gray400;
  margin-bottom: 16px;
}

/* CONFIRMED */

.logo {
  display: block;
  margin-inline: auto;
  width: 200px;
  height: auto;
}

.confirmedHeader {
  font-size: 2.5rem;
  color: $gray500;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  margin-bottom: 4px;
}

.confirmedHelper {
  font-size: 2rem;
  color: $gray500;
  display: block;
  margin-bottom: 16px;
}

.confirmedBody {
  font-size: 1.1rem;
  color: $gray500;
  display: block;
  margin-bottom: 16px;
}
