@import 'styles/_variables.scss';

.container {
  width: fit-content !important;
  padding-block: 0 !important;

  div {
    padding: 0 !important;
  }
}

.popupItem {
  cursor: pointer;
  padding: 1rem;
  display: flex;
  gap: 0.5rem;
  transition: background-color 100ms linear;

  &:hover {
    background-color: $lightGray50;
  }

  i {
    color: $darkGreen;
  }
}
