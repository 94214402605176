@import 'styles/_variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.title {
  font-size: 1.1rem;
  margin-right: 16px;
}
