@import 'styles/_mixins.scss';

.textEditorRoot {
  border-color: $gray300;
  box-shadow: 0 2px 10px hsl(0deg, 0%, 81%);

  & :global(.quill) {
    & :global(.ql-container) {
      height: 100%;

      & :global(.ql-editor) {
        max-width: none;
        min-height: 200px;
        max-height: 250px;
        overflow: auto;
        @include scrollbar;
      }
    }

    blockquote {
      border-color: $primary400 !important;
    }

    a {
      color: $primary600 !important;
      text-decoration: underline;
    }

    img {
      margin-block: 8px;
      margin-inline: auto;
      max-height: 300px;
      border-radius: 3px;
      box-shadow: $shadow2;
    }
  }
}

.toolbar {
  background-color: $gray200;
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border-bottom: 1px solid $gray300;
}

.toolbarControl {
  color: $gray500;

  &:global(.ql-active) {
    color: $primary600;
  }
}
