@import "styles/_variables.scss";

.stepContainer {
  display: flex;
  align-items: center;
  flex-direction: column;

  .stepTitle {
    display: flex;
    font-size: 1.2rem;
    align-items: center;
    margin-bottom: 1.2rem;

    > i {
      width: 2.5rem;
      display: flex;
      color: $white;
      height: 2.5rem;
      align-items: center;
      border-radius: 1rem;
      background: $darkGreen;
      justify-content: center;
    }

    > span {
      font-weight: bold;
      margin-left: 0.4rem;
    }
  }

  .stepDescription {
    width: 100%;

    color: $gray;
    font-size: 1rem;
    margin-bottom: 1rem;

    @media (min-width: $breakLG) {
      width: 80%;
    }
  }

  .form {
    width: 100%;

    @media (min-width: $breakLG) {
      width: 80%;
    }
  }
}
