@import 'styles/_variables.scss';

.wrapper {
  position: relative;
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.noPreview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  gap: 24px;

  & p {
    font-size: calc(24 / 16 * 1rem);
    font-weight: 500;
    color: $gray500;

    &::after {
      content: '...';
    }
  }
}

.helper {
  width: 100%;
  color: $gray400;
  text-align: left;
  margin-top: -16px;
  font-style: italic;
}
