@import 'styles/_variables.scss';

.main {
  margin-top: var(--size);
  width: auto;
  max-width: 100%;
  height: 1px;
}

.colorized {
  background-color: $primary500;
}
