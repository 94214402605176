@import 'styles/_variables.scss';

.footer {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
  background-color: $black3;
  padding: 1rem 3rem;
  position: relative;

  @media (min-width: $breakLG) {
    flex-direction: row;
    max-height: 15rem;
  }

  .leftSection {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: fit-content;
    text-align: center;

    .link {
      color: white;
      text-decoration: underline;
    }

    .logo {
      width: 12.5rem;
      margin-inline: auto;

      @media (min-width: $breakLG) {
        margin-left: -48px;
      }
    }

    @media (min-width: $breakLG) {
      text-align: revert;
    }
  }

  .rightSection {
    flex: 1;
    align-items: center;
    display: flex;
    flex-direction: column;

    @media (min-width: $breakLG) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    .list {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding-inline: 8px;
      text-align: left;
      align-items: center;
      gap: 1rem;
      padding-block: 1rem;
      border-bottom: 1px solid white;
      text-align: center;

      &:last-child {
        border: none;
      }

      @media (min-width: $breakLG) {
        border-bottom: none;
        border-right: 1px solid white;
        text-align: revert;
      }

      .listContainer {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @media (min-width: $breakLG) {
          align-items: flex-start;
        }
      }
    }
  }
}

.contactsLinks {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .icon {
    padding: 0.35rem;
    border-radius: 100%;
    background-color: white;
    color: black;
  }

  &:visited {
    color: white;
  }
}

.policiesLinkContainer {
  & > a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.reportErrorsButton {
  font-size: 1rem;
  align-self: center;
  margin-bottom: auto;
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
