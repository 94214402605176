@import 'styles/_variables.scss';

.wrapper {
  width: 100%;
  background-color: $white;
  margin-block-start: 1rem;
  border-radius: 6px;
  box-shadow: $boxShadow;
  padding: 1rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;

  @media (min-width: $breakMD) {
    flex-direction: row;
  }

  .days {
    min-width: fit-content;
    flex-shrink: 0;
  }

  h4 {
    font-size: 1.5rem;
    color: $gray;
  }

  .totalDays {
    font-weight: bold;
    font-size: 1.5rem;
    color: $gray;

    span {
      font-size: 3rem;
      color: $darkGreen;
    }
  }

  .resumeOfDays {
    width: 100%;
    flex-basis: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    .resumeType {
      color: $gray;
      font-size: 0.875rem;

      .decoration {
        display: inline-block;
        margin-right: 0.75rem;
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 50%;
        background-color: $lightGrayText;
      }

      .total {
        font-weight: bold;
        margin-left: 0.5rem;
      }
    }
  }
}
