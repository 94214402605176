:root {
  --primary: #507938;
}

.ck.ck-editor {
  display: flex;
  flex-direction: column;
  height: 100% !important;
  max-width: 100% !important;
}

.ck.ck-editor__main {
  height: 100%;
  display: flex;
  overflow-y: auto;
}

.ck.ck-editor__main > .ck.ck-content {
  width: 100%;
}

.ck-balloon-panel {
  z-index: 9999 !important;
}

.ck.ck-editor__main a {
  color: var(--primary);
  text-decoration: underline;
}

th .ck-table-bogus-paragraph {
  color: var(--primary);
}

.ck blockquote {
  border-color: var(--primary);
}

#calendar-container .ck-editor__editable_inline {
  min-height: 200px;
  max-height: 300px;
}
