@import 'styles/_variables.scss';

.form {
  display: flex;
  flex-direction: column;
}

.button {
  width: 95% !important;
  margin-inline: auto !important;
  margin-top: 2rem !important;
  padding: 1rem 2rem !important;
  background-color: $darkGreen !important;
  color: $white !important;
  font-weight: bold !important;

  &:hover {
    background-color: $darkGreen2 !important;
  }
}

.loadingIconContainer {
  display: grid;
  place-items: center;
  margin-top: 1.5rem;
  overflow: hidden;
}
