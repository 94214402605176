@import 'styles/_variables.scss';

.wrapper {
  height: 48px;
  display: flex;
  margin-bottom: 16px;
  border-bottom: 1px solid $gray200;
  align-items: center;
}

.separator {
  color: $gray300;
  margin-inline: 4px;
}

.baseLink {
  font-size: calc(18 / 16 * 1rem);
  color: $gray500;
  padding: 8px 16px;
  border-radius: 8px;

  &:hover {
    text-decoration: none;
    background-color: $gray200;
  }
}

.baseLink.active {
  color: $gray700;
  font-weight: 500;
}

.loadingColor {
  height: fit-content;
  color: $gray300;
}

.goBack {
  padding: 8px;
  color: $gray500;
  margin-right: 12px;
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    color: $gray700;
    background-color: $gray200;
  }
}

.over {
  border-radius: 8px;
  background-color: $gray200;
  border: 2px solid $primary500;
}

.breadcrumbs {
  overflow: hidden;

  ol {
    display: block !important;
    width: 100% !important;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;

    li {
      display: inline-block;

      &:first-of-type {
        overflow: var(--hide) !important;
      }
    }
  }
}
