@import 'styles/_variables.scss';

.warning {
  background-color: $gray200;
  color: $gray400;
  padding: 12px;
  border-radius: 14px;
  margin-block: 16px;
}

.userSearchWrapper {
  margin-block: 16px;
}

.userResults {
  height: 200px;
  overflow-y: auto;
  background-color: $gray200;
}

.userResult {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 10px;

  &:hover {
    background-color: $gray400;
    cursor: pointer;
  }

  &.selected {
    background-color: $lightGreen;

    &:hover {
      background-color: $lightGreen2;
      cursor: pointer;
    }
  }
}

.noUsers {
  margin: 16px;
  color: $gray400;
}
