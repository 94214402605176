@import 'styles/_variables.scss';

.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  margin-block: 24px;

  @media (max-width: $breakMD) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: $breakSM) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 1.1rem;
  font-weight: 500;
  text-transform: capitalize;

  & > i {
    font-size: 1rem;
    margin-right: 8px;
    color: $gray400;
  }
}

.contentSection {
  min-height: 100vh;
}

.sectionTitleWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-bottom: 12px;
  border-bottom: solid $gray200 1px;
  margin-block: 24px;

  & .arrowLeft {
    font-size: 1rem;
    color: $gray500;

    &:hover {
      color: $gray600;
    }
  }
}

.sectionTitle {
  margin-top: 0;
  color: $gray500;
  font-weight: 500;
  text-transform: capitalize;
}
