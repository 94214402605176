@import 'styles/_variables.scss';

.wrapper {
  padding-top: 24px;
  padding-bottom: 32px;
  border-bottom: 1px solid $primary500;
  display: flex;
  flex-direction: column;

  &:last-of-type {
    border-bottom: none;
  }
}

.title {
  color: $primary500;
  font-size: calc(20 / 16 * 1rem);
  font-weight: 600;
  margin-bottom: 32px;
}
