@import 'styles/_mixins.scss';

.wrapper {
  position: absolute;
  top: 0;
  left: 12px;
  width: 355px;
  height: 100%;
  background-color: $gray600;
  padding: 24px;
  color: $white;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  @include scrollbar;
}

.closeButton {
  cursor: pointer;
  position: sticky;
  top: 0;
  align-self: flex-end;
  font-size: 1.25rem;

  &:hover {
    color: $gray200;
  }
  z-index: 1;
}

.title {
  font-size: 1.75rem;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 48px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.buttons {
  display: flex;
  gap: 24px;

  & > * {
    flex: 1 !important;
    font-size: 0.875rem !important;
    font-weight: bold !important;
  }
}

.generalInformationHelper {
  margin-bottom: 8px;
}
