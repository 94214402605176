@import 'styles/_mixins.scss';

.form {
  display: flex !important;
  flex-direction: column !important;
  gap: 1.25rem;
}

.textEditorRoot {
  margin-inline: 0;

  & :global(.quill) {
    & :global(.ql-container) {
      & :global(.ql-editor) {
        min-height: 200px;
        max-height: 300px;
        overflow: auto;
        @include scrollbar;
      }
    }
  }
}

.imageInputField {
  & > label {
    justify-content: flex-start;
    padding-left: 14px;
  }
}

@media (min-width: $breakLG) {
  .mainContainer {
    margin-inline: -64px;
  }
}
