@import 'styles/_variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.body {
  color: $gray400;
}

.expirationDate {
  margin-top: 24px;

  & > strong {
    color: $error400;
  }
}

.paymentMethod {
  margin-block: 16px;
}

.card {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 0.85rem;
  color: $gray500;
  margin-bottom: 4px;
}

@media (min-width: $breakMD) {
  .wrapper {
    flex-direction: row;
    justify-content: space-between;
  }
}
