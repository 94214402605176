@import 'styles/_mixins.scss';

.chipsContainer {
  margin-block: 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  max-height: 12.5rem;
  overflow-y: scroll;
  @include scrollbar;
}
