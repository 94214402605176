@import 'styles/variables.scss';

.wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-block-start: 48px;
  padding-block: 48px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -30%;
    width: 160vw;
    height: 100%;
    z-index: -1;
    background-color: $white;
  }
}

.header {
  font-size: bold;
  font-size: 2rem;
  color: $gray600;
  margin-bottom: 56px;
}

// For the table

.tableContainer {
  width: 100%;
  overflow-x: auto;

  @media (min-width: $breakLG) {
    margin-top: 36px;
    overflow-x: visible;
  }
}

.tableWrapper {
  margin-inline: auto;
  width: 100%;
  min-width: 1200px;
  color: $gray700;
  border-collapse: collapse;
  overflow-x: hidden;
}

.tableRowWrapper {
  & td {
    padding: 16px;
    border: 2px solid $gray500;

    &:first-child {
      font-weight: bold;
    }
  }

  & th {
    isolation: isolate;
    position: relative;
    text-transform: uppercase;
    padding: 24px;
    font-size: 2rem;
    border: 2px solid $gray500;

    background-color: $gray400;
    color: $white;
    height: max-content;

    &:first-of-type {
      color: $gray600;
      background-color: $white;
    }

    &:last-of-type {
      background-color: $primary400;
      color: $white;
      border: 2px solid $gray500;

      @media (min-width: $breakLG) {
        border: none;
        background-color: transparent;
      }
    }
  }
}

.tableRowWrapper.tableRowEven {
  background-color: $gray300;
}

@media (min-width: $breakMD) {
  .tableWrapper {
    min-width: 0;
  }
}

.proPlanHeader {
  display: flex;
  background-color: $primary400;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: $breakLG) {
    position: absolute;
    width: calc(100% + 3px);
    height: calc(100% + 70px);
    top: -68px;
    border: 2px solid $gray500;
    margin-inline: -24px;
    margin-left: -26px;
    z-index: 2;
  }
}
