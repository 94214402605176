@import 'styles/_variables.scss';

.wrapper {
  position: relative;
  width: 100%;
}

.label {
  padding-inline: 16px;
  min-height: 3.4rem;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-radius: 0.25rem;
  border: 1px solid $lightGrayText;
  transition: all 100ms linear;

  &:hover {
    border: 1px solid $gray;
  }
}

.clearButton {
  padding: 0 !important;
  font-size: calc(18 / 16 * 1rem) !important;
  margin-left: 8px !important;

  &:hover {
    background-color: transparent !important;
  }
}

.fileInput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute !important;
  z-index: -1;
}
