@import "styles/_variables.scss";

.menuItem {
  border-left: 4px solid $darkGreen !important;
  background-color: $lightGreen2 !important;
}

.userAvatar {
  margin-left: auto;
  border: 0.1rem solid $darkGreen;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    border: 0.2rem solid $darkGreen;
    transition: all 0.3s;
  }
}

.userMenu {
  div {
    min-width: 12rem;
    margin: 3rem 0 0;

    @media (min-width: $breakXL) {
      margin-left: -4.5rem;
    }

    a,
    li {
      text-decoration: none;
      color: $black2;

      &:hover {
        color: $darkGreen;
        transition: all 0.2s;
      }
    }

    i {
      margin-right: 0.4rem;
    }

    &:first-child {
      margin: 0;
    }
  }

  .basicUserInfo {
    display: flex;
    flex-direction: column;
    width: 12rem;
    height: 3.8rem;
    text-align: center;
    padding-top: 0.5rem;
    border-bottom: 0.1rem solid $lightGray2;

    span {
      &:last-child {
        font-size: 0.8rem;
        color: $gray;
        margin-top: 0.2rem;
      }
    }
  }
}
