.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 16px;
  gap: 16px;
}

.buttons_row{
  display: flex;
  gap: 10px;
}