@import 'styles/_variables.scss';

.commentContainer {
  display: flex;
  gap: 1.25rem;
  margin-bottom: 1.5rem;
}

.comment {
  width: 100%;
  border: 1px solid $gray100;
  padding: 1rem 1.5rem;
  border-radius: 6px;

  .author {
    color: $gray400;
    margin-bottom: 1em;

    span {
      color: $primary600;
      font-weight: bold;
    }
  }

  .commentContent {
    font-size: 1rem;

    h1 {
      font-size: 2.1rem;
    }

    h2 {
      font-size: 1.6rem;
    }

    h3 {
      font-size: 1.1rem;
    }

    h4 {
      font-size: 0.9rem;
    }

    @media (min-width: $breakMD) {
      h1 {
        font-size: 3rem;
      }

      h2 {
        font-size: 2.4rem;
      }

      h3 {
        font-size: 1.7rem;
      }

      h4 {
        font-size: 1.3rem;
      }
    }

    h1,
    h2,
    h3,
    h4 {
      font-weight: bold;
      margin-bottom: 0.5em;
    }

    p {
      line-height: 1.75;
    }

    a {
      color: $primary600;
    }

    a:hover {
      color: $primary400;
    }

    blockquote {
      margin-top: 0;
      margin-left: 0;
      margin-bottom: 12px;
      padding-left: 16px;
      color: #495057;
      border-left: 4px solid $primary400;
    }

    ul,
    ol {
      margin-top: 12px;
      padding-left: 32px;
      list-style-position: outside;
    }

    li {
      padding-block: 0.25em;
    }

    p:global(.ql-align-center) {
      text-align: center;
    }

    p:global(.ql-align-right) {
      text-align: right;
    }

    pre {
      font-size: 0.9rem;
      color: $white;
      background-color: $gray500;
      padding: 4px 8px;
      margin-block: 8px;
      border-radius: 6px;
      white-space: pre-wrap;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.relativeTime {
  color: $gray300;
  font-size: 0.75rem;
}
