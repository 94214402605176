@import 'styles/_mixins.scss';

.buttonWrapper {
  display: flex;
  justify-content: end;
  margin: 1.25rem 0;

  .recurrentActionsButton {
    & > * {
      display: flex !important;
      gap: 0.75rem !important;
    }

    background-color: $darkGreen !important;
    color: $white !important;
    width: max-content;

    &:disabled {
      cursor: not-allowed !important;
      box-shadow: none;
      background-color: $lightGray2 !important;
      color: gray !important;
    }
  }
}

.mainContainer {
  background-color: $lightGray3;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  margin-bottom: 5rem;
  border-radius: 1.5rem;
  box-shadow: $shadow2;
  overflow: hidden;

  @media (min-width: $breakLG) {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 1.2rem;
  }
}

.calendarLeft {
  height: 50%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
  margin-bottom: 1.5rem;

  header {
    .today {
      color: $darkGreen;
      font-weight: 700;
      letter-spacing: 0.05rem;
    }

    .fullDate {
      font-size: 1.5rem;
      color: $black;
    }
  }

  .eventsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden;
    margin-bottom: 1rem;
    max-height: 40vh;

    h3 {
      width: 100%;
    }

    .title {
      color: $darkGreen;
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }

  .eventsWrapper {
    flex: 1;
  }

  .events {
    width: 100%;
    display: block;
    height: 30vh;
    list-style: none;
    padding: 0;
    grid-template-columns: 1fr;
    gap: 1rem;
    overflow-y: auto;
    @include scrollbar;

    @media (min-width: $breakLG) {
      gap: 1.3rem;
      margin-bottom: 1rem;
      height: 100%;
    }
  }

  .noEvents {
    color: $lightGrayText;
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  .button {
    display: block;
    background-color: $darkGreen;
    color: $white;
    font-size: 1.15rem;
    transition: all 200ms linear;
    box-shadow: $shadow;

    .icon {
      margin-right: 0.5rem;
    }

    &:hover {
      background-color: $darkGreen2;
    }

    &:disabled {
      cursor: not-allowed !important;
      box-shadow: none;
      background-color: $lightGray;
      color: gray;
    }
  }

  @media (min-width: $breakLG) {
    height: 100%;
    width: 30%;
    padding: 1.5rem;
    margin-bottom: 0;
    gap: 2rem;
  }
}

.calendarRight {
  padding: 1rem;
  background-color: $white;
  border-left: solid 1px $lightGray2;

  header {
    .title {
      font-size: 1.75rem;
      color: $black2;
      margin-bottom: 1rem;
    }

    .buttons {
      display: flex;
      margin-bottom: 2rem;
      border-radius: 0.25rem;

      .button {
        cursor: pointer;
        padding: 0.5rem 1rem;
        background-color: white;
        border: 1px solid $lightGray2;
        outline: none;
        font-size: 1.25rem;
        color: $black2;
        letter-spacing: 0.025rem;

        &:hover {
          color: $darkGreen;
        }

        &:focus {
          z-index: 1222;
          box-shadow: 0 0 0 3px $lightGreen2;
        }
      }
    }

    @media (min-width: $breakMD) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .title {
        font-size: 2rem;
      }
    }
  }

  .daysGrid {
    display: flex;
    flex-direction: column;

    .chargingSection {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
    }

    .daysRow {
      display: flex;
      overflow: hidden;

      .dayItem {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 0.25rem;
        width: 100%;
        min-width: 14%;
        font-size: 1.2rem;
        transition: color 100ms linear;

        .dayText {
          padding-top: 0.2rem !important;
          padding-right: 0.2rem !important;
        }

        &:hover {
          color: $primary600 !important;
        }

        .postCount,
        .massEmailCount,
        .eventsCount {
          width: calc(100% - 8px);
          text-align: center;
          margin-inline: auto;
          padding: 4px 8px;
          color: $white;
          font-size: 0.75rem;
          border-radius: 16px;

          .complementary {
            display: none;

            @media (min-width: $breakMD) {
              display: inline;
            }
          }
        }

        .postCount {
          background-color: $posts;
        }

        .massEmailCount {
          background-color: $emails;
        }

        .eventsCount {
          background-color: $events;
          margin-bottom: 4px;
        }
      }

      .today {
        .dayText {
          position: relative;
          color: $white;
          padding: 4px;
          margin-right: 4px;
          border-radius: 50%;
          margin-bottom: 0.2rem;
          transition: color 100ms linear;
          z-index: 1;

          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 20px;
            height: 20px;
            background-color: $primary600;
            border-radius: 50%;
            z-index: -1;
          }
        }

        &:hover {
          .dayText {
            color: $black2 !important;
          }
        }
      }

      .selected {
        border: 1px solid $darkGreen2 !important;
        color: $darkGreen2;
      }

      .dayInactive {
        color: $lightGrayText;
        border: 1px solid $lightGray2 !important;

        &:hover {
          color: $lightGrayText !important;
        }

        &.today {
          .dayText {
            color: $lightGrayText;
            background-color: transparent;
            transition: color 100ms linear;
          }
        }
      }
    }

    .daysRow:first-child {
      margin-bottom: 0.5rem;
      font-size: 1.25rem;

      .dayItem {
        color: $lightGrayText;
      }

      @media (min-width: $breakMD) {
        margin-bottom: 0.75rem;
        font-size: 1.5rem;

        .dayItem {
          color: $lightGrayText;
        }
      }
    }

    .daysRow:not(:first-child) {
      cursor: pointer;
      width: 100%;
      text-align: right;
      font-weight: 700;
      min-height: 5.5rem;

      .dayItem {
        & > section {
          display: flex;
          flex-direction: column;
          gap: 0.25rem;
          align-items: flex-end;

          @media (min-width: $breakMD) {
            font-size: 1.25rem;

            & > section {
              overflow-y: auto;
            }
          }
        }

        font-size: 0.8rem;
        border: 1px solid $lightGray2;
        border-collapse: collapse;
      }

      .dayItem:nth-child(6),
      .dayItem:nth-child(7) {
        background-color: $lightGray;
      }
    }
  }

  @media (min-width: $breakMD) {
    padding: 2rem;
    flex-grow: 1;
  }
}
