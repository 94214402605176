@import 'styles/_variables.scss';

.loadingMessage {
  display: flex;
  align-items: center;
  gap: 16px;
}

.message {
  color: $gray300;

  &::after {
    content: '...';
  }
}
