@import 'styles/_mixins.scss';

.header {
  margin: 1rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .crudTitle {
    margin: 0;
  }

  i {
    cursor: pointer;
    color: $black2;
    transition: color 100ms linear;
    padding-bottom: 0.25rem;

    &:hover {
      color: $gray;
    }
  }
}

.crudPanel {
  width: 100%;
  display: grid;
  gap: 1rem;
  margin-bottom: 1.5rem;

  .crudButtonsContainer {
    display: grid;
    gap: 1rem;
    width: 100%;
  }

  @media (min-width: $breakLG) {
    display: flex;
    align-items: flex-start;

    .crudButtonsContainer {
      display: flex;
      height: 100%;
      gap: 1rem;
      width: fit-content;
      max-width: 40%;
      flex-shrink: 0;
    }
  }

  .crudButton {
    padding-block: 0.8rem;
    display: flex;
    min-width: fit-content !important;

    > span {
      &:first-child {
        > i,
        span {
          margin-right: 0.5rem;
        }
      }
    }
  }
}
