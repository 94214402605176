@import 'styles/_variables.scss';

.wrapper {
  margin-block: 32px;
}

.indicatorItemWrapper {
  position: relative;
  height: 100%;
  width: 100%;

  & > * {
    border: none;
  }
}

.indicatorMenuIcon {
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 24px;

  &:hover {
    color: $gray500;
  }
}

.deleteMenuItem {
  color: $error600 !important;
}

.menuIcon {
  min-width: 0 !important;
  margin-right: 8px !important;
}
