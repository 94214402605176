@import 'styles/_variables.scss';

.header {
  display: flex;
  align-items: baseline;
  font-size: 1.1rem;
  color: $gray600;
  margin-bottom: 24px;

  i {
    color: $primary500;
    margin-inline: 8px;
  }
}

.headerHelper {
  color: $gray500;
  margin-top: -16px;
}
