@import 'styles/_variables.scss';

.wrapper {
  padding: 8px 16px;
  border-radius: 8px;
  background-color: $gray200;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper p {
  color: $gray400;
}

.button {
  color: $gray400 !important;
  transition: all 0.1s ease-in-out !important;

  &:hover {
    color: black !important;
  }
}

.buttonActive {
  font-weight: bold !important;
  color: black !important;
}
