@import 'styles/_variables.scss';

.wrapper {
  padding-inline: 42px;
}

.mainContainer {
  margin-top: 24px;
}

.pageActionsWrapper {
  display: flex;
  align-items: baseline;
  gap: 16px;
}

.trashIcon {
  cursor: pointer;
  color: $gray400;

  &:hover {
    color: $gray500;
  }
}

.addPageButtonLabel {
  color: $gray400 !important;
  transition: color 120ms linear;

  &:hover {
    color: $black !important;
  }
}
