@import 'styles/_variables.scss';

.oopsError {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  text-align: center;
  margin-top: 5rem;

  .description {
    margin-top: 1rem;
  }

  a {
    text-decoration: none;
    display: block;
    margin-top: 2rem;
  }
}
