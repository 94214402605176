@import 'styles/_mixins.scss';

.dialogHeader {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  font-size: 1rem !important;
  margin-bottom: 16px;

  .titleContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;

    .title {
      width: fit-content;
      font-size: 1rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    .backButton {
      color: $black !important;
      margin-right: 1em !important;
      padding: 0 !important;

      &:hover {
        background-color: transparent !important;
      }
    }
  }

  .close {
    cursor: pointer;
    color: $gray;
    font-size: 1.25rem;
    transition: color 200ms linear;

    &:hover {
      color: $black2;
    }
  }
}

.paper {
  padding: 1.5rem !important;
  border-radius: 12px !important;
  overflow-y: hidden !important;
}

.contentContainer {
  padding: 0 !important;
  padding-top: 1rem !important;
  overflow-x: hidden;
  overflow-y: scroll;
  @include scrollbar;

  @-moz-document url-prefix() {
    & {
      padding-right: 16px !important;
    }
  }
}
