@import 'styles/_variables.scss';

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  & p {
    color: $gray500;
  }
}

@media (min-width: $breakMD) {
  .contentContainer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & p {
      max-width: 50%;
    }
  }
}
