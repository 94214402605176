@import 'styles/_variables.scss';

.contentSection {
  margin-bottom: 24px;
  border-bottom: 1px solid $gray200;

  &:last-of-type {
    border-bottom: none;
  }
}

.title {
  color: $gray800;
  margin-bottom: 8px;
}

.paragraph {
  margin-bottom: 16px;
  color: $gray600;
  line-height: 1.65;
  font-size: calc(18 / 16 * 1rem);

  & > a {
    font-weight: 500;
    color: $primary500;

    &:hover {
      text-decoration: underline;
    }
  }
}

.tutorials {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-bottom: 24px;
  margin-top: 32px;

  & > div {
    background-color: white;
    border-radius: 8px;
    padding: 16px 16px 36px 16px;
    box-shadow: $shadow2;
  }

  @media (max-width: $breakMD) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

.tutorialTitle {
  font-size: 1.5rem;
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin-bottom: 16px;
  transition: color 0.12s ease-in-out;

  &:hover {
    color: $primary500;
  }
}

.tutorialLinks {
  font-size: calc(18 / 16 * 1rem);
  padding: 0;
  list-style: none;

  & > li {
    color: $gray600;
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }

    & > a {
      display: block;

      &:hover {
        color: $primary500;
        text-decoration: underline;
      }
    }
  }
}
