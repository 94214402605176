@import 'styles/_variables.scss';

.dialog {
  margin: 0 auto;
  width: 24.75rem !important;
  max-width: 100% !important;
  padding: 1rem !important;
}

.imageWrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  .coverImage {
    width: 100%;
    height: auto;
    border-radius: 0.25rem;
  }
}

.postContent {
  width: 100% !important;
  font-size: 1rem !important;
  letter-spacing: 0 !important;
  margin-bottom: 1.25em !important;

  @media (min-width: $breakMD) {
    font-size: 1.25rem !important;
  }
}

.footerText {
  width: 100%;
  font-size: 0.75rem !important;
  letter-spacing: 0 !important;
  margin-bottom: 0.25rem;

  @media (min-width: $breakMD) {
    font-size: 1rem !important;
  }
}
