@import 'styles/_mixins.scss';

.header {
  width: 100%;
  background: $white;
  height: 4.8rem;
  border-bottom: 0.1rem solid $lightGray2;

  .content {
    @extend %ajustedContent;
    height: 100%;
    align-items: center;
    justify-content: space-between;

    .settings {
      display: flex;
      gap: 1rem;
      align-items: center;
    }

    img {
      width: 8rem;
    }
  }
}
