@import 'styles/_variables.scss';

.valueItemWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

.valueItemHeader {
  display: flex;
  align-items: baseline;
}

.valueItemTitle {
  font-size: 1.1rem;
  margin-right: 16px;
}

.deleteIcon {
  cursor: pointer;
  color: $gray300;
  transition: color 120ms linear;

  &:hover {
    color: $error600;
  }
}
