@import 'styles/_mixins.scss';

.container {
  display: grid;
  gap: 2rem;
}

.mainTitle {
  margin-bottom: 1rem;
}

.item {
  flex-basis: 100%;

  .title {
    color: $darkGreen;
    font-weight: bold;
    margin-bottom: 0.25rem;
  }

  .value {
    color: $gray;
  }
}

.twoRows {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.fileItem {
  display: flex;
  gap: 1rem;
  align-items: center;

  i {
    cursor: pointer;
    transition: color 100ms linear;
    flex-shrink: 0;
    flex-basis: 30%;

    &:hover {
      color: $gray2;
    }
  }
}

.users {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  max-height: 200px;
  overflow: auto;
  @include scrollbar;
}

.employee {
  display: flex;
}

.participantWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.avatar {
  width: 24px !important;
  height: 24px !important;
  font-size: 0.75rem !important;
}
