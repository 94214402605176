@import 'styles/_variables.scss';

.wrapper {
  margin-block-start: 16px;
  margin-block-end: 32px;
  max-height: 300px;
  overflow-y: auto;
}

.title {
  margin-bottom: 12px;
}

.personWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.nameWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: calc(14 / 16 * 1rem);
  margin-left: 16px;

  p {
    color: $gray500;
  }
}

.imageWrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.roleWrapper {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-weight: 500;
}

.deleteIcon {
  cursor: pointer;
  margin-left: 16px;

  &:hover {
    color: $error700;
  }
}
