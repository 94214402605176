@import 'styles/_variables.scss';

.columnName {
  cursor: pointer;
  color: $primary600;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    color: $primary700;
  }
}
