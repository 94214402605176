@import 'styles/_variables.scss';

.wrapper {
  padding: 16px 16px;
  border: 1px solid $gray300;
  border-radius: 6px;
  user-select: none;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  & .iconContainer {
    flex: 1;
  }

  & .title {
    flex: 6;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: calc(14 / 16 * 1rem);
    white-space: nowrap;
    color: $gray700;
  }
}

.overflowHidden {
  cursor: pointer;
  overflow: hidden;
  border-radius: 6px;

  &:hover {
    background-color: $gray200;
  }
}

.over {
  outline: $primary500 solid 2px;
}
