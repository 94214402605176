@import 'styles/_variables.scss';

.userPermission {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  div:first-child {
    display: flex;
    gap: 10px;
  }

  .imageUser {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: gainsboro;
  }
}

.wrapper {
  padding-top: 8px;
  height: 200px;
  overflow-y: auto;
  margin-bottom: 16px;
  color: $gray600;
}

.name {
  font-weight: 500;
  margin-bottom: 4px;
}

.email {
  color: $gray400;
  font-size: calc(14 / 16 * 1rem);
}

.rolContainer {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding-right: 4px;

  & > div {
    flex: 1;
  }
}

.helper {
  color: $gray400;
  font-size: calc(14 / 16 * 1rem);
}
