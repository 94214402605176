@import 'styles/_variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid $gray200;
  border-radius: 8px;
  padding: 16px;
  overflow: hidden;
  background-color: white;
  box-shadow: $shadow2;

  & details {
    margin-inline: -16px;
    border-bottom: 1px solid $gray200;
    padding: 24px;

    & summary {
      display: flex;
      justify-content: space-between;
      list-style: none;
      font-weight: 500;
      font-size: calc(24 / 16 * 1rem);
      cursor: pointer;
      transition: all 0.12s linear;

      &:hover {
        color: $primary700;
      }
    }

    & p {
      margin-top: 16px;
      font-size: calc(18 / 16 * 1rem);
      line-height: 1.75;
      color: $gray700;
    }

    &:first-of-type {
      margin-top: -16px;
    }

    &:last-of-type {
      margin-bottom: -16px;
      border-bottom: none;
    }
  }
}
