.dataGridTable {
  width: 100%;
  height: 24rem;

  > div {
    &:first-child {
      > div {
        &:nth-child(2) {
          > div {
            &:first-child {
              > div {
                > div {
                  > div {
                    &:first-child {
                      > div {
                        padding: 0 !important;

                        > div {
                          font-weight: bold !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .iconButton {
    > span {
      > i {
        font-size: 1rem;
      }
    }
  }
}

.cell {
  padding-inline: 20px !important;

  > div {
    > div {
      > div {
        font-size: 1rem !important;
        font-weight: bold !important;
      }
    }
  }
}

.rowCell {
  padding-inline: 20px !important;
}

.paper {
  border-radius: 10px !important;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.22);
}
