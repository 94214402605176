@import 'styles/_variables.scss';

.pendingLabel {
  margin-inline: auto;
  background-color: $gray !important;
  color: $white !important;
  border-radius: 4px !important;
  width: 100% !important;
}

.approvedLabel {
  margin-inline: auto;
  background-color: $accentGreen2 !important;
  color: $white !important;
  border-radius: 4px !important;
  width: 100% !important;
}

.rejectedLabel {
  margin-inline: auto;
  background-color: $error400 !important;
  color: $white !important;
  border-radius: 4px !important;
  width: 100% !important;
}
