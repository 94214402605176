@import 'styles/_variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin-inline: auto;
}

.footer {
  margin-top: 0;
}

@media (min-width: $breakLG) {
  .wrapper {
    width: 70%;
  }
}
