@import 'styles/_variables';

.container {
  height: 100%;
  display: grid; 
  grid-template-columns: 180px 5fr; 
  grid-template-rows: 1fr; 
  grid-template-areas: 
    "sidebar routes"; 
}

@media (max-width: $breakLG) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
      "routes";
  }
}

.sidebar { grid-area: sidebar; }

.routes { 
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  grid-area: routes; 
}