@import 'styles/_variables.scss';

.wrapper {
  cursor: pointer;
  border: 1px solid $gray300;
  border-radius: 6px;
  overflow: hidden;
  user-select: none;

  &:hover {
    & .titleContainer {
      background-color: $gray200;
    }
  }
}

.thumbnailWrapper {
  width: 100%;
  height: 150px;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.emptyThumbnail {
  background-color: $white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: $gray500;

  i {
    font-size: calc(20 / 16 * 1rem);
  }

  p {
    font-size: small;
    letter-spacing: 0.025rem;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  background-color: white;
  color: $gray600;
  gap: 16px;
  height: 40px;
}

.iconContainer {
  flex: 1;
}

.title {
  flex: 6;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: calc(14 / 16 * 1rem);
  white-space: nowrap;
}

.dragging {
  opacity: 0.5;
  outline: 3px dotted $primary500;
}
