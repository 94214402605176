@import 'styles/_variables.scss';

.pagesContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.stepperCounter {
  display: flex;
  margin-top: 0.75em;
  justify-content: center;
}

.stepperDot {
  border-radius: 50%;
  margin: 0 0.5em;
  width: 10px;
  height: 10px;
  background-color: $lightGray6;
}

.stepperActiveDot {
  background-color: $darkGreen;
}
