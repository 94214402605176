@import 'styles/_variables.scss';

.wrapper {
  margin-block: 32px;
  padding: 24px 32px;
  display: flex;
  @include box;

  @media (max-width: $breakMD) {
    flex-direction: column;
    gap: 24px;
  }
}

.avatarSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 2px solid $primary500;

  @media (max-width: $breakMD) {
    border: none;
    border-bottom: 2px solid $primary500;
    padding-bottom: 24px;
  }
}

.title {
  color: $primary500;
  font-size: calc(18 / 16 * 1rem);
  text-transform: uppercase;
  margin-block: 0;
  margin-bottom: 24px;
}

.avatar {
  width: 126px !important;
  height: 126px !important;
  font-size: calc(126px / 1.5) !important;
}

.informationSection {
  flex: 3;
  padding-left: 32px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  min-height: 0;
  height: fit-content;

  @media (max-width: $breakMD) {
    padding-left: 0;
  }

  @media (max-width: $breakSM) {
    grid-template-columns: 1fr;
  }
}

// For the information item
.informationItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.informationItemTitle {
  color: $gray600;
  font-weight: 600;
  font-size: 1rem;
}

.informationItemValue {
  color: $gray500;
  font-size: 1rem;
}

.available {
  color: #517b38;
}

.unavailable {
  color: #f06e6e;
}
