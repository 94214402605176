@import 'styles/mixins.scss';

.wrapper {
  padding-top: 0 !important;
  margin-top: -24px !important;
}

.header {
  margin-top: 0;
  margin-bottom: 1.45rem;

  .title {
    text-align: center;
    font-size: 1.25rem;
    color: $completelyBlack;
  }
}

.modalContainer {
  padding-inline: 0.5rem;

  .form {
    display: flex;
    flex-direction: column;
    gap: 1.75rem;

    .textarea {
      width: 100%;
      resize: none;
      height: 7.5rem;
      border: 1px solid $lightGray5;
      border-radius: 4px;
      outline: none;
      padding: 1rem;
      font-size: 1rem;
      color: $gray;
      font-family: 'Roboto', sans-serif;

      &:focus {
        border-color: $darkGreen;
      }
      @include scrollbar;
    }

    .attachFilesContainer {
      display: flex;
      padding-bottom: 0.5rem;
      width: 100%;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: 1px solid $gray;

      .attachFilesList {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        max-height: 150px;
        overflow: auto;
        margin-right: 16px;
        @include scrollbar;
      }

      .searchFilesText {
        color: $gray;
      }

      .searchFilesButton {
        padding: 0.5rem 2rem;
        border-radius: 6px;
      }

      .fileInputHidden {
        display: none;
      }
    }

    .sendButton {
      display: block !important;
      margin-inline: auto !important;
      width: 93% !important;
      padding-block: 1rem !important;
      border-radius: 6px !important;
    }
  }
}
