@import 'styles/_mixins.scss';

.wrapper {
  margin-inline: -24px !important;

  & > .section {
    padding-inline: 24px !important;
  }
}

.button {
  justify-content: flex-start !important;
  color: $gray400 !important;
}

.hasSelected {
  color: $white !important;
}

.searchWrapper {
  display: flex;
  border-bottom: 1px solid $gray300;
  padding-bottom: 16px;

  @media (max-width: $breakSM) {
    flex-direction: column;
    gap: 16px;
  }
}

.searchInput {
  flex: 2 !important;
  margin-right: 16px !important;

  @media (max-width: $breakSM) {
    margin-right: 0 !important;
  }
}

.selectAll {
  flex: 1 !important;
  min-width: fit-content !important;
}

.checkboxesGroup {
  overflow-y: auto;
  flex-wrap: nowrap !important;
  padding-block: 16px !important;
  padding-inline: 24px !important;
  max-height: 300px !important;
  @include scrollbar;
}

.seeClients {
  opacity: 0 !important;
  transition: opacity linear 100ms;
}

.checkboxWrapper:hover .seeClients {
  opacity: 1 !important;
}

.clientList {
  list-style-position: inside;
  padding: 0;
}

.empty {
  color: $gray500;

  &::after {
    content: '...';
  }
}
