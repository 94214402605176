@import 'styles/_mixins.scss';

.mainContainer {
  margin-block: 16px;
  display: grid;
  color: $gray600;

  .imageContainer {
    margin-bottom: 16px;
    max-height: 260px;

    strong {
      display: block;
      margin-bottom: 16px;
    }

    img {
      max-height: 100%;
      border-radius: 8px;
    }
  }

  .informationSection {
    display: grid;
    row-gap: 16px;
    max-height: 260px;

    & > div:nth-child(2) {
      margin-bottom: 16px;
    }

    .textContainer {
      display: flex;
      flex-direction: column;
      min-height: 100%;

      .content {
        padding: 16px;
        overflow-y: auto;
        flex-grow: 1;
        border: 1px solid $gray100;
        border-radius: 3px;
        word-wrap: break-word;
        @include scrollbar;
      }
    }
  }
}

@media (min-width: $breakMD) {
  .mainContainer {
    grid-template-columns: 32% 68%;
    grid-template-rows: minmax(0, fit-content);

    .imageContainer {
      padding-right: 24px;
      margin-bottom: 0;
    }

    .informationSection {
      height: 100%;
      grid-template-rows: max-content 1fr;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 32px;

      & > div:nth-child(2) {
        margin-bottom: 0;
      }

      .textContainer {
        grid-column: span 3;
      }

      .postContentItem {
        margin-bottom: 0;
      }
    }
  }
}
