@import "styles/_mixins.scss";

.drawerList {
  width: 16rem;

  .menuItem {
    color: $black2;
    text-decoration: none;

    &.active {
      > div {
        > div {
          > span,
          > i {
            color: $darkGreen;
            font-weight: bold;
          }
        }
      }
    }
  }

  .optionItem {
    padding-left: 2.5rem;
  }
}
