@import 'styles/_mixins.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 2px solid $gray300;
  border-radius: 6px;
  background-color: $white;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.22);
  height: 100%;
}

.title {
  display: block;
  color: $gray500;
  font-size: 1.25rem;
}

.valueItemsWrapper {
  display: grid;
  grid-template-columns: repeat(var(--grid-value), 1fr);
  gap: 12px;
  padding-top: 36px;
  overflow: auto;
  @include scrollbar;
}

.valueItemWrapper {
  padding: 12px;
  border: 2px solid $primary500;
  border-radius: 6px;
  text-align: center;
  height: fit-content;
}

.valueItemTitle {
  display: block;
  color: $gray400;
  font-size: 1.1rem;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.valueItemCount {
  font-size: 3rem;
  font-weight: bold;
  color: $primary500;
}
