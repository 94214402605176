@import 'styles/_variables.scss';

.checkboxesContainer {
  max-height: 50vh;
  overflow-y: scroll;
  margin-block: 0.75rem;
}

.checkboxLabel {
  display: grid;
  gap: 0.1rem;

  .title {
    font-size: 1.1rem;
    font-weight: bold;
  }

  .helper {
    color: $gray;
  }
}

.formGroup {
  margin-bottom: 1rem;
}

.totals {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: right;
  margin-bottom: 2rem;
}

footer {
  display: flex;
  justify-content: center;
}

.emptyContainer {
  margin-bottom: 1rem;

  .empty {
    font-size: 1.1rem;
    color: $gray;
    margin-bottom: 1rem;
  }
}
