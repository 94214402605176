@import 'styles/_variables.scss';

.mainContent {
  border-block: $gray100 1px solid;
  margin-inline: -24px;
  padding-inline: 24px;
  padding-block: 16px;
  display: grid;
  align-items: center;

  .title {
    color: $primary600;
    margin-bottom: 32px;
    padding-right: 8px;
  }

  .headerItemsContainer {
    color: $gray600;
  }
}

@media (min-width: $breakMD) {
  .mainContent {
    grid-template-columns: 32% 68%;

    .title {
      margin-bottom: 0;
    }

    .headerItemsContainer {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-items: center;
      text-align: center;
    }
  }
}
