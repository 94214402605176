@import 'styles/_variables.scss';

.mainContainer {
  padding-inline: 0;
}

.title {
  font-size: 1.25rem;
  text-align: center;
  color: $gray600;
  margin-bottom: calc(8px + 1.25rem);
}

.actionSection {
  display: flex;
  justify-content: center;
  margin-top: 32px !important;
}

.buttonRoot {
  width: 80% !important;
  font-weight: bold !important;
  padding-block: 1.1rem !important;
}

@media (min-width: $breakLG) {
  .mainContainer {
    padding-inline: 64px;
  }
}
