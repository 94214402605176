@import 'styles/_variables.scss';

.header {
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;

  h2 {
    color: $gray;
  }
}

.tabsBox {
  margin-bottom: 16px;
}

.tab {
  font-weight: bold !important;
  text-transform: none !important;
}

.breakContainer {
  display: flex !important;
  flex-direction: column !important;
  line-height: 0 !important;
  gap: 1.3rem;
}

.emptyBreaks {
  color: $gray;
}

.monthlyMain {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: $breakLG) {
    flex-direction: row;
    gap: 2rem;
  }

  & > * {
    flex-basis: 100%;
  }

  .resumen {
    padding: 1rem;
    background-color: $accentGreen2;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: $boxShadow;

    h4 {
      font-size: 1.5rem;
      text-transform: uppercase;
      margin-bottom: 1.25rem;
    }

    p {
      color: $darkGreen;
      font-size: 3rem;
      font-weight: bold;
    }
  }

  .details {
    width: 100%;

    .tableHeader {
      font-weight: bold;
    }

    .date {
      color: $darkGreen;
    }
  }
}

.breakShowcase {
  display: grid;
  gap: 1.25rem;

  .article {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    i {
      cursor: pointer;
      transition: color 100ms linear;

      &:hover {
        color: $error600;
      }
    }
  }
}
