@import 'styles/_variables.scss';

.icon {
  font-size: calc(64 / 16 * 1rem);
  margin-bottom: 16px;
}

.wrapper {
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $gray600;
}

.wrapper p {
  margin-top: 4px;
  margin-bottom: 24px;
}
