@import 'styles/_variables.scss';

.wrapper {
  margin-bottom: 32px;
  padding: 24px 32px;
  @include box;
}

.header {
  width: auto;
  display: flex;
  align-items: center;
  margin-inline: -32px;
  padding-inline: 32px;
  border-bottom: 1px solid $gray200;
  padding-bottom: 24px;
  gap: 32px;
}

.headerTitle {
  font-size: calc(20 / 16 * 1rem);
  color: $gray500;
}

.tabsRoot {
  margin-inline: auto !important;
}

.tab {
  text-transform: none !important;
  color: $gray500 !important;
}

.tabSelected {
  font-weight: bold !important;
}

.freeTimeHeaderWrapper {
  position: relative;
}

.badge {
  font-weight: bold;
  position: absolute;
  background-color: $error600;
  color: white;
  z-index: 10;
  top: -10px;
  right: -24px;
  line-height: 12px;
  padding: 4px 6px;
  font-size: 0.85rem;
  border-radius: 50%;
}
