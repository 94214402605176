@import 'styles/_variables.scss';

.button {
  width: fit-content;
  margin-left: auto;
}

.hideFileInput {
  position: fixed;
  right: -100%;
}

.menuIcon {
  min-width: 36px;

  i {
    font-size: 20px;
  }
}

.divider {
  margin-block: 4px;
}

.wrapper {
  min-width: 300px;
}

.input {
  margin-bottom: 24px !important;
}

.cancelButton {
  padding: 16px !important;
}

.svgIcon {
  width: 24px;
  height: 24px;
}
