@import 'styles/_mixins.scss';

.searchBar {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.25rem;
  padding-bottom: 16px;
  border-bottom: 1px solid $gray300;

  @media (min-width: $breakLG) {
    flex-direction: row;
  }
}

.clients {
  max-height: 300px;
  overflow-y: auto;
  @include scrollbar;
}

.labelRoot {
  span {
    &:global(.MuiCheckbox-colorPrimary.Mui-checked) {
      color: $darkGreen !important;
    }
  }
}

.selectAll {
  width: 100%;
  border-color: $darkGreen !important;
  color: $darkGreen !important;

  &:hover {
    border-color: $darkGreen2 !important;
    color: $darkGreen2 !important;
    background-color: transparent !important;
  }

  @media (min-width: $breakLG) {
    width: 35% !important;
    flex-shrink: 0;
  }
}

.allSelected {
  background-color: $darkGreen !important;
  color: $white !important;

  &:hover {
    color: $white !important;
    background-color: $darkGreen2 !important;
  }
}

.clientNotFound {
  color: $lightGrayText;
}

.selectClientsButton {
  display: block !important;
  text-align: left !important;
  height: 3.4rem !important;
  color: $lightGrayText !important;
}

.selectedClients {
  background-color: $darkGreen !important;
  color: $white !important;

  &:hover {
    background-color: $darkGreen2 !important;
  }
}

.dialogRoot {
  isolation: isolate !important;
  position: relative !important;
  margin-inline: -24px !important;

  & > section {
    padding-inline: 24px !important;
  }
}
