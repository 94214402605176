.row__table {
  width: 15%;
}

.container__email {
  margin-top: 1rem;
  border: 1px lightgrey solid;
  padding: 1rem;
  min-height: 300px;
  max-height: 300px;
  overflow-y: auto;
}
