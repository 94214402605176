@import 'styles/_mixins.scss';

.createButton {
  margin-left: auto !important;
}

.firstSection {
  display: flex;
  flex-direction: column;
  margin-block-end: 32px;
  // margin-top: calc(-32px + -24px);

  @media (min-width: $breakMD) {
    margin-top: calc(-32px + -24px);
  }
}

.legends {
  width: 100%;
  display: flex;
  gap: 2rem;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 1rem;

  .legendContainer {
    display: flex;
    align-items: baseline;

    .color {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: var(--color);
      margin-right: 1rem;
    }

    .title {
      font-weight: bold;
      margin-bottom: 0.25rem;
    }

    .helper {
      color: $gray;
    }
  }
}

.calendar {
  border: none !important;
  padding: 0;
}

.labelBase {
  width: calc(100% - 0.5rem);
  margin-block: 0.25rem;
  margin-inline: auto;
  padding: 0.25rem;
  border-radius: 4px;
  display: none;

  @media (min-width: $breakLG) {
    justify-content: center;
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
}

.vacationLabel {
  background-color: $lightGreen;
  color: $darkGreen2;
}

.eventLabel {
  background-color: $inProgress100;
  color: $inProgress600;
}

.birthdayLabel {
  background-color: $error100;
  color: $error600;
}

.more {
  width: calc(100% - 0.5rem);
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0.25rem 0.5rem;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  background-color: $lightGray2;
  transition: all 100ms linear;
  border-radius: 4px;
  margin-bottom: 4px;
  margin-inline: auto;

  &:hover {
    background-color: $lightGray;
  }
}

.popupContainer {
  background-color: white;
  padding: 0.5rem 1rem;
  display: grid;
  gap: 1rem;
  max-height: 10rem;
  overflow-y: auto;
  @include scrollbar;

  .popupItem {
    cursor: pointer;
    display: flex;
    gap: 0.75rem;
    align-items: center;

    i {
      flex-basis: 10%;
      flex-shrink: 0;
    }

    &.vacation {
      color: $darkGreen;
    }
    &.event {
      color: $inProgress600;
    }
    &.birthday {
      color: $error600;
    }

    .label {
      font-weight: bold;
      color: $gray;
    }

    &:hover {
      .label {
        color: $black;
      }
    }
  }
}
