@import 'styles/_variables.scss';

.day {
  position: relative;
  padding-bottom: 12px;

  .addButton {
    opacity: 0;
    position: absolute;
    bottom: 2px;
    right: 2px;
    color: $gray400 !important;
    transition: opacity 100ms linear;

    &:hover {
      color: $gray500 !important;
    }
  }

  &:hover {
    .addButton {
      opacity: 1;
    }
  }
}

.tooltipColors {
  opacity: 1;
  background-color: $gray600 !important;
}

.action {
  position: relative;
  width: 95%;
  margin-inline: auto;
  padding: 4px;
  border-radius: 16px;
  font-size: 0.8rem;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: $white !important;

  &:last-of-type {
    margin-bottom: 8px;
  }
}

.deleteIcon {
  cursor: pointer;
  position: absolute;
  font-size: 0.8rem;
  color: $black2;
  right: 4px;
  top: 6px;

  &:hover {
    color: $black;
  }
}
