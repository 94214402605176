@import 'styles/_variables.scss';

.header {
  background-color: $white;
  margin-inline: -10%;
  padding-inline: 10%;
  margin-top: -1rem;
  padding-block: 1rem;
  margin-bottom: 36px;
  border-bottom: 1px solid $gray300;
}

.maxWidthWrapper {
  position: relative;
  margin-inline: auto;
  width: 90%;
  max-width: 1080px;

  @media (min-width: 768px) {
    width: 80%;
  }

  & h1 {
    font-size: 2.5rem;
  }

  & h2 {
    font-size: 2rem;
  }
}

.flexContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $breakSM) {
    flex-direction: column;
    align-items: flex-start;

    & > div {
      align-self: flex-end;
    }
  }
}

.page {
  max-width: none;
}
