.wrapper {
  min-width: 300px;
}

.menuIcon {
  min-width: 36px;
}

.divider {
  margin-block: 4px;
}
