@import 'styles/_variables.scss';

.wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 64px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -30%;
    width: 160vw;
    height: 100%;
    z-index: -1;
    background-color: $white;
  }
}

.text {
  flex: 1;
  flex-shrink: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 24px;

  & button {
    width: fit-content !important;
  }
}

.header {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 12px;
}

.subheader {
  font-size: 1.5rem;
  line-height: 35px;
  letter-spacing: 0.3px;
  color: $gray500;
  margin-bottom: 64px;
  max-width: 80%;
}

.image {
  flex: 1.5;
  flex-shrink: 0;
  margin-inline: -5%;
}

@media (min-width: $breakLG) {
  .wrapper {
    max-height: 70vh;
    flex-direction: row;
    align-items: center;
  }

  .text {
    margin-bottom: 0;
  }
}
