@import 'styles/_mixins.scss';

.mainContainer {
  padding: 24px;
  background-color: $white;
  border-radius: 10px;
  box-shadow: $shadow;

  .title {
    color: $gray600;
    font-size: 1.25rem;
    margin-bottom: 1.5em;
  }

  .commentsSection {
    width: 100%;
    margin-block-end: 1.5rem;
    max-height: 500px;
    overflow: auto;
    @include scrollbar;

    @media (min-width: $breakMD) {
      padding-inline: 2rem;
    }
  }

  .comment {
    padding: 1rem;
    border: 1px solid $gray100;
    border-radius: 10px;

    @media (min-width: $breakMD) {
      margin-inline: 2rem;
    }

    .createComment {
      cursor: pointer;
      display: flex;
      gap: 1em;
      color: $gray200;
      transition: color 120ms linear;

      &:hover {
        color: $gray300;
      }
    }
  }
}

.buttons {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1.5rem;
}

.emptyComments {
  color: $gray400;
  font-size: 0.9rem;
}
