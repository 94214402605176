@import 'styles/_mixins.scss';

.root {
  height: 100% !important;
}

.content {
  height: 100% !important;
  display: flex;
  flex-direction: column;
}

.paper {
  height: 100% !important;
}

.information {
  flex: 1;
  overflow-y: scroll;
  padding: 0 1rem;
  @include scrollbar;
}

.header {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1.25rem;

  & > * {
    flex-basis: 100%;
  }

  h4 {
    font-size: 1.25rem;
    color: $darkGreen;
  }

  .status {
    display: flex;
    gap: 1rem;
    align-items: center;

    div {
      width: 100%;
      padding-block: 1.25rem;
    }
  }

  @media (max-width: $breakMD) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}

.firstSection {
  display: flex;
  margin-bottom: 1.25rem;
  flex-wrap: wrap;

  @media (min-width: $breakMD) {
    flex-wrap: nowrap;
  }

  & > * {
    flex-basis: 100%;
  }

  .control {
    display: grid;
    text-align: center;

    & > * {
      padding: 0.5rem;
    }

    .label {
      font-weight: bold;
      background-color: $gray200;
      border: 1px solid $gray400;
      border-bottom: none;
    }

    .value {
      border: 1px solid $gray400;

      &:last-child {
        border-bottom: 1px solid $gray400;
      }
    }
  }
}

.secondSection {
  display: grid;
  margin-bottom: 1.25rem;

  h5 {
    text-align: center;
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  .control {
    display: flex;
    border-left: 1px solid $gray400;
    border-right: 1px solid $gray400;
    border-bottom: none;
    flex-wrap: wrap;
    text-align: center;

    @media (min-width: $breakMD) {
      flex-wrap: nowrap;
      text-align: left;
      border: 1px solid $gray400;
      border-bottom: none;

      &:nth-child(2) {
        font-weight: bold;
        background-color: $gray200;
      }
    }

    &:last-child {
      border-bottom: 1px solid $gray400;
    }

    & > * {
      padding: 1rem;
    }

    .label {
      flex-basis: 100%;
      flex-shrink: 0;
      border-top: 1px solid $gray400;
      border-bottom: 1px solid $gray400;
      font-weight: bold;

      @media (min-width: $breakMD) {
        flex-basis: 70%;
        border-top: none;
        border-bottom: none;
        border-right: 1px solid $gray400;
      }
    }

    .value {
      flex-basis: 100%;
      flex-shrink: 0;

      @media (min-width: $breakMD) {
        flex-basis: 30%;
      }
    }
  }
}

.thirdSection {
  width: 70%;
  padding-bottom: 2rem;
  display: grid;
  margin-left: auto;

  .control {
    display: flex;
    border: 1px solid $gray400;
    border-bottom: none;

    &:last-child {
      border-bottom: 1px solid $gray400;
    }

    & > * {
      padding: 1rem;
      flex-basis: 100%;
    }

    .label {
      background-color: $gray200;
      border-right: 1px solid $gray400;
      font-weight: bold;
    }
  }
}

.actions {
  padding-top: 1rem;
  border-top: $lightGray4 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .downloads {
    display: flex;
    align-items: center;

    p {
      color: $gray;
      flex-basis: 40%;
    }

    .downloadTypes {
      display: flex;
      gap: 2rem;

      .downloadType {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.25rem;
        transition: color 100ms linear;

        i {
          cursor: pointer;
          font-size: 2rem;
        }

        &:hover {
          color: $gray;

          i:hover {
            color: $gray;
          }
        }
      }
    }
  }

  .email {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;

    p {
      color: $gray;
      flex-basis: 40%;
      flex-shrink: 0;
    }

    div {
      display: flex;
      align-items: center;
      overflow: hidden;
      gap: 0.5rem;
    }
  }
}

.imageBlob {
  position: absolute;
  top: -10000px;
  z-index: -1000;
}

.bankAccount {
  font-size: 0.875rem;
  color: $gray500;
}

.copy {
  cursor: pointer;
  margin-left: 4px;
}
