@import 'styles/_variables';

.sidebar { 
  background-color: $gray700;
  padding-block: 2rem;

  grid-area: sidebar; 

  .navigation {
    margin-top: 5rem;

    .navigationList {
      display: flex;
      flex-direction: column;
      list-style: none;
      padding: 0;
    }
  }
}


