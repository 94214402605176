@import 'styles/_mixins.scss';

.textEditor {
  margin-inline: 0;
  margin-bottom: 16px;
}

.rootClass {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  margin-left: 0 !important;
}

.timePickers {
  display: grid;
  gap: 24px;
}

.usersSwitch {
  margin-block: 16px;
}

.usersChips {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  max-height: 100px;
  overflow-y: auto;
  @include scrollbar;
}
