@import 'styles/_variables.scss';

.descriptionWrapper {
  margin-block-start: 16px;
  margin-block-end: 24px;
}

.description {
  border: 1px solid $gray100;
  padding: 16px 20px;
  border-radius: 4px;
  min-height: 120px;
  max-height: 120px;
  overflow-y: auto;
}

.fileSection {
  margin-top: 24px;
  padding-top: 16px;
  padding-inline: 24px;
  margin-inline: -24px;
  border-top: 1px solid $gray100;
  color: $gray600;

  .file {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    .fileInfo {
      font-size: 0.85rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .icon {
      padding-left: 16px;
      border-left: 1px solid $gray600;
      cursor: pointer;

      &:hover {
        color: $gray500;
      }
    }
  }
}
