@import 'styles/_variables.scss';

.wrapper {
  margin-top: -16px;
  padding-inline: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.warningIcon {
  width: 48px;
  height: 48px;
  background-color: $error400;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-inline: auto;
}

.successIcon {
  width: 48px;
  height: 48px;
  background-color: $completed600;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-inline: auto;
}

.warningHelper {
  font-weight: bold;
  color: $gray500;
  margin-block: 24px;
}

.buttons {
  width: 100%;
  display: flex;
  gap: 24px;
  padding-inline: 24px;
}
