@import 'styles/_variables.scss';

.mainContainer {
  position: relative;
  padding: 1rem;
  background-color: $white;
  box-shadow: $shadow;
  border-bottom: 1px solid $lightGray;
  display: flex;
  gap: 2rem;

  .close {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    color: $gray2;
  }

  .messageContainer {
    p {
      font-size: 1.25rem;
      color: $gray;
      margin-bottom: 0.25rem;
    }

    button {
      border: none;
      outline: none;
      padding: 0.5rem 1.5rem;
      color: $white;
      background-color: $darkGreen;
      border-radius: 6px;
      font-size: 1.05rem;
    }
  }
}
