// For the credit card form
.creditCardWrapper {
  margin-inline: auto;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.cardExtraInfoWrapper {
  display: flex;
  gap: 16px;

  & > * {
    flex: 1 !important;
  }
}
