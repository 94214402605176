@import 'styles/_variables';

.headerContainer { 
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  grid-area: header;

  .header {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

@media (max-width: $breakMD) {
  .headerContainer {
    flex-direction: column;
    gap: 0.5rem;

    .header {
      margin-bottom: 1rem;
      width: 100%;

      .title {
        display: block;
        width: 100%;
        margin-top: 0;
        text-align: center;
      }
    }
  }
}