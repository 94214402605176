@import 'styles/_variables.scss';

.contentItem {
  width: 100%;
  border: none;
  color: $gray600;
  text-align: left;
  margin-bottom: 16px;
  padding-right: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }

  strong {
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
  }
}

.alignCenter {
  text-align: left;
}

.marginBottom0 {
  margin-bottom: 0;
}

@media (min-width: $breakMD) {
  .contentItem {
    margin-bottom: 0;
  }

  .alignCenter {
    text-align: center;
  }

  $border: 1px solid $gray100;

  .borderInline {
    border-inline: $border;
  }
  .borderLeft {
    border-left: $border;
  }
  .borderRight {
    border-right: $border;
  }
}
