@import 'styles/_variables.scss';

.icon {
  cursor: pointer;
  color: $gray;
  transition: color 100ms linear;

  &:hover {
    color: $black;
  }
}
