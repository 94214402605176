@import 'styles/_mixins.scss';

body,
html {
  height: 100%;
}

.wrapper {
  display: grid;
  grid-template-columns: 200px 2fr;
  height: 100%;
  overflow-y: hidden;
}

.navigation {
  position: sticky;
  top: 0;
  left: 0;
  background-color: $gray600;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
}

.pagesList {
  list-style: none;
  padding: 0;
  color: $white;
  width: 100%;
}

.item {
  cursor: pointer;
  padding: 16px 24px;
  font-weight: bold;
  font-size: 1.25rem;
  flex-basis: 100%;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;

  &:hover {
    background-color: $primary500;
  }

  transition: background-color 120ms linear;
}

.item.selected {
  position: relative;
  background-color: $primary500;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 10px;
    background-color: $primary400;
    transition: background-color 120ms linear;
  }

  &:hover {
    &::after {
      background-color: $primary500;
    }
  }
}

.addButton {
  margin-bottom: 16px !important;
  width: calc(100% - 1rem) !important;
  color: $white;
}

.input {
  color: $white !important;
}

.header {
  margin: 42px;
  margin-bottom: 0;
  display: flex;
  align-items: baseline;
  gap: 16px;
  height: 56px;
}

.title {
  font-size: 2rem;
  margin: 0 !important;
}

.searchInput {
  width: 35% !important;
  margin-left: auto !important;
}

.reportButton {
  height: 100% !important;
  align-self: flex-start;
}

.loadingPages {
  margin-block: 48px;
}

.selectedPageWrapper {
  position: relative;
  overflow-y: auto;
}

.backButton {
  width: 80% !important;
  margin-block: 16px !important;
}
