@import 'styles/_variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  gap: 24px;
}

.figure {
  width: fit-content;
  padding: 48px;
  background-color: $gray300;
  border-radius: 27px;

  & > i {
    font-size: 64px;
    color: $gray100;
  }
}

.helper {
  font-size: 1.25rem;
  font-weight: bold;
  color: $gray500;
}
