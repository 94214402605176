@import 'styles/_mixins.scss';

.title {
  font-size: 2rem;
  margin-bottom: 1em;
}

.information {
  position: relative;
  background-color: $white;
  display: grid;
  border-radius: 10px;
  box-shadow: $shadow;
  padding: 24px;
  padding-top: 48px;
  gap: 2rem;
  grid-template-columns: 1fr 0.1fr;

  @media (min-width: $breakMD) {
    grid-template-columns: 1fr 1fr 0.05fr;
    gap: 24px;
  }

  .description {
    border-right: 2px solid $primary500;
  }

  & .descriptionItem {
    color: $gray700;

    & strong {
      display: inline-block;
      margin-bottom: 0.5em;
    }

    & p {
      color: $gray600;
      max-height: 260px;
      overflow: auto;
      line-height: 1.5;
      @include scrollbar;

      &.paddingRight {
        @media (min-width: $breakLG) {
          padding-right: 2rem;
        }
      }
    }
  }

  .deatils {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    grid-column: span 2;
    height: fit-content;

    @media (min-width: $breakMD) {
      grid-column: 2;
    }
  }

  .editIconSection {
    position: absolute;
    top: -24px;
    right: 24px;
    display: flex;
    justify-content: flex-end;
    grid-row: 1;
    grid-column: 2;

    i {
      cursor: pointer;
      height: max-content;
      color: $gray500;
      transition: color 100ms linear;

      &:hover {
        color: $gray700;
      }
    }

    @media (min-width: $breakMD) {
      grid-column: 3;
      grid-row: 1;
    }
  }
}
