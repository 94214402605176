@import 'styles/_mixins.scss';

.remindersSection {
  margin-bottom: 24px;
}

.formGroupRoot {
  color: $gray600 !important;
  margin-bottom: 16px !important;
}

.formGroupLabel {
  color: $gray600;
  margin-bottom: 8px;
  font-weight: bold;
}

.textFieldRoot {
  margin-top: 24px !important;
}
