@import 'styles/_variables.scss';

.wrapper {
  @include box;
  padding: 24px;
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 24px;
}

.totalItem {
  border-radius: 6px;
  border: 1px solid black;
  text-align: center;
  padding: 12px 16px;
}

.totalItemTitle {
  color: black;
  margin-bottom: 4px;
}

.totalItemValue {
  font-weight: 500;
  font-size: calc(32 / 16 * 1rem);
}

.totalItemValueGreen {
  color: $primary500;
}

.totalItemValueRed {
  color: #f06e6e;
}
