@import 'styles/_variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: calc(100% - 4.8rem);
  justify-content: center;
  align-items: center;
  padding: 0 24px;

  @media (min-width: $breakLG) {
    height: calc(100% - 4.8rem - 3.2rem);
  }
}

.errorCode {
  display: block;
  font-size: 6rem;
  font-weight: bold;
  color: $primary500;
  margin: 0;
}

.header {
  margin: 0;
  font-size: 1.5rem;
  color: $gray500;

  @media (min-width: $breakLG) {
    font-size: 2.25rem;
    margin-top: -24px;
  }
}

.body {
  text-align: center;
  font-size: 1.1rem;
  color: $gray500;
  line-height: 1.5;
  letter-spacing: 0.025em;

  @media (min-width: $breakLG) {
    max-width: 650px;
  }
}
