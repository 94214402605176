@import 'styles/_variables.scss';

.loadingWrapper {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
}

.spinner {
  display: inline-block;
  width: 100px;
  height: 100px;
  border: 6px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: $darkGreen;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}