@import 'styles/_mixins.scss';
@import 'styles/_variables.scss';

.header {
  margin: 1rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .crudTitle {
    margin: 0;
  }

  i {
    cursor: pointer;
    color: $black2;
    transition: color 100ms linear;
    padding-bottom: 0.25rem;

    &:hover {
      color: $gray;
    }
  }
}

.crudPanel {
  width: 100%;
  display: grid;
  gap: 1rem;
  margin-bottom: 1.5rem;

  .crudButtonsContainer {
    display: grid;
    gap: 1rem;
    width: 100%;
  }

  @media (min-width: $breakLG) {
    display: flex;
    align-items: flex-start;

    .crudButtonsContainer {
      display: flex;
      height: 100%;
      gap: 1rem;
      width: fit-content;
      max-width: 40%;
      flex-shrink: 0;
    }
  }

  .crudButton {
    padding-block: 0.8rem;
    display: flex;
    min-width: fit-content !important;

    >span {
      &:first-child {

        >i,
        span {
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.subHeader {
  margin-bottom: 1rem;
}

.filterPanel {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.emptyState {
  width: 100%;
  display: flex;
  margin-top: 4rem;
  justify-content: center;
}

@media (min-width: $breakLG) {
  .toolsList {
    .toolsContent {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .filterPanel {
    flex-direction: row;
  }
}


.formContainer {
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.exampleText {
  background-color: #e0e0e0;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.exampleText {
  margin: 0;
}

.buttonContainer {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.blockCard {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 100%;

  h4 {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
  }

  span {
    color: #6b7280;
    /* Gray color */
    height: auto;
    overflow-y: auto;
  }
}

.blockCard:hover {
  border: solid 2px #507938;
  transform: scale(1.03);
}

.itemTitle {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  ;
}

.iconContainer {
  padding: 10px;
  border: #507938 1px solid;
  width: 45px;
  height: 45px;
  border-radius: 25%;
  margin-right: 10px;

  span {
    color: #507938;
  }
}

.page {
  //background-color: rgba(238, 242, 255 , 1);
  height: 100%;
}

.aiBlockTitle {
  color: #507938;
  font-size: 1.5rem;
  font-weight: 600;
}

.aiBlockSubTitle {
  color: grey;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 20px;
}