@import 'variables.scss';

%ajustedContent {
  display: flex;
  width: 90%;
  max-width: 1080px;
  margin: 0 auto;

  @media (min-width: $breakMD) {
    width: 80%;
  }
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $lightGrayText;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $lightGrayText;
    border-radius: 15px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
}
