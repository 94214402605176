@import 'styles/_variables.scss';

.root {
  margin-block: 1.25rem !important;
}

.breacrumsLink {
  color: $darkGreen;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.selected {
    font-weight: bold;
  }
}
